import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment'
import { Login,LoginResponse } from './login';
import { tap} from 'rxjs/operators';
import { Router } from '@angular/router';
import { NotificationsService } from 'angular2-notifications';
import { StartComponent } from 'src/app/start/start.component';
import { Subject } from 'rxjs';
import { HoteldataService } from 'src/app/services/hoteldata.service';
import { CurrentConfig } from 'src/app/services/current-config';

@Injectable({
  providedIn: 'root'
})

export class AuthService {

  sessionStatus : Subject<Object> = new Subject<object>();
  sStatus :boolean = false;

  constructor(
    private _httpClient : HttpClient,
    private _router : Router,
    public _notifications: NotificationsService,
    private _hotelData : HoteldataService
  ) {

  }

  get token(): string | null {
    return sessionStorage.getItem('access_token');
  }

  set token(value) {
    sessionStorage.setItem('access_token', value);
  }

  get logged(): boolean {
    return this.token != null;
  }

  login(form: Login) {
    return this._httpClient.post(environment.login, form).pipe(
      tap((loginResponse: LoginResponse) => {
        this.token = loginResponse.token;
        this.sessionStatus.next();
      })
    );
  }

  settings:Object ={
    animate: "fromLeft",
    clickToClose: true,
    pauseOnHover: true,
    showProgressBar: true,
    timeOut: 2000
  }

  types = ['alert', 'error', 'info', 'warn', 'success'];

  logout() {
    sessionStorage.removeItem('access_token');
    // localStorage.clear();
    this.sessionStatus.next();
    this._router.navigate(['']);
    this._notifications.info(
      "Cierre de sesión",//title
      "La sesión ha expirado",//content
      this.settings //override
    );
    // setTimeout(() => {
    //   localStorage.clear();
    //   window.location.reload(true);
    // }, 500);
  }
}
