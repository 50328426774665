import { Injectable } from '@angular/core';
import { UserdataService } from './userdata.service';
import * as moment from 'moment';
import 'moment/locale/es';
import { Subject } from 'rxjs';
import { HoteldataService } from './hoteldata.service';

export interface test{
  id:any,
  hotel_clave:string,
  visita:number,//1,2,3
  status?:boolean|string, //{'bloqueado','activo'}
  puntuacion_final?:number|string,
  playing_score?:number,
  limit_score?:number //-> total de la suma del puntaje,
  preguntas?:string;
  secciones?:string
  fecha?:any,
  usuario:string
}

export interface foto{
  name:string,
  url:string,
  id?:number
}

export interface questions{
  id:any,
  cuestionario_id?:any,
  seccion_clave:string,
  checked?:boolean,
  marca?:number, //rubrica de gravedad
  pregunta:string,
  valor:number,
  respuesta:boolean|string,
  notas?:Array<string>,
  fotos?:Array<foto>,
  hora:string,
  fecha:string,
  capex:boolean
}

export interface storedTest {
  id: string | number,
  hotel_clave: string,
  visita: number,
  secciones : Array<string>,
  playing_score: number,
  limit_score: number,
  fecha: string,
  usuario: string,
  preguntas: Array<number|boolean|string>
}

@Injectable({
  providedIn: 'root'
})
export class TestdataService {

  currentCuestionarioChange:Subject<any> = new Subject<any>();
  savedTestChange:Subject<any> = new Subject<any>();
  savedTest: Array<storedTest> = [];

  constructor(
    public _hoteldata : HoteldataService,
    private _userData : UserdataService
  ) {
    this.currentCuestionarioChange
      .subscribe( () => {
        localStorage.setItem("cuestionarios",JSON.stringify(this.Cuestionarios));
    });

    this.savedTestChange
      .subscribe( ()=>{
        this.checkStoredData();
    });
  }



  testChanges(){
    this.currentCuestionarioChange.next();
    //let a = document.addEventListener("change",function(event){console.log(event)})
  }

  saveChanges(){
    this.savedTestChange.next();
  }

  testRestore(){
    let ltest = localStorage.getItem("cuestionarios");
    if(ltest){
      this.Cuestionarios = JSON.parse(ltest);
    }
  }


  Cuestionarios:Array<test>=[
    //{id:"f70BU", hotel_clave:"FIGDL",visita:3,status:true,puntuacion_final:0,fecha:"",usuario:"amerchant"},
  ];

  //cambiar localStorage a httpRequest via REST API
  uploadData(data:storedTest){
    this.saveChanges();
    let fd:storedTest = this.savedTest.find( stest => { return stest.id == data.id });
    let index:number = this.savedTest.indexOf(fd);

    if(index == -1) this.savedTest.push(data);
    else this.savedTest[index] = data;

    localStorage.setItem("stored",JSON.stringify(this.savedTest));
    this.savedTestChange.next();

  }

  checkStoredData(){
    let saved = localStorage.getItem("stored");
    // console.log("saved",saved);

    if(!saved) return;
    this.savedTest = JSON.parse(saved);
    this.savedTest.forEach( test =>{
      this.generateNewTest(test.hotel_clave,true,test.hotel_clave,test.visita,test.usuario, test.fecha,test.playing_score,test.limit_score);
    });
    //console.log("savedTest",this.savedTest);
  }


  reviewCuestionarios(h_clave){
    let sameHotel : Array<test> = [];
    this.Cuestionarios.forEach(cuestionario => {
      if (cuestionario.hotel_clave == h_clave) sameHotel.push(cuestionario);
    })
    return sameHotel;
  }

  generateNewTest(_hotel_clave:string,exist:boolean,id?:string,visita?:number,user?:string,fecha?:string,pscore?:number,lscore?:number){
    this._hoteldata.restore();

    let _sameHotel = this.reviewCuestionarios(_hotel_clave);

    let genTest:test = {
      id: exist? id : "TS"+_hotel_clave[0]+_hotel_clave[1]+this._userData.makeRandomKey(),
      hotel_clave: _hotel_clave,
      visita:0,//por cambiar
      status:true,
      playing_score: exist ? pscore : 0,
      limit_score: exist ? lscore : 0,
      fecha: exist ? fecha : moment().format('DD[/]MM[/]YYYY'),
      usuario: this._hoteldata.currentConfig.data.user.user_mail,
    }

    switch (_sameHotel.length) {
      case 0:
        genTest.visita = 1;
      break;
      case 1:
        genTest.visita = 2;
      break;
      case 2:
        genTest.visita = 3;
      break;
      case 3:
        //console.log("No se pueden añadir más de 3 tests");
      break;
    }

    if(exist){
      genTest.visita = visita;
      return [true,genTest.id];
    }

    if(_sameHotel.length < 3){
      this.Cuestionarios.push(genTest);
      return [true,genTest.id];
    }
  }

  deleteSavedData(eraseId:string){
    this.saveChanges();
    let erase = this.savedTest.find( stest => { return stest.id==eraseId});
    let indexErase = this.savedTest.indexOf(erase);

    this.savedTest.splice(indexErase,1);
    localStorage.setItem("stored",JSON.stringify(this.savedTest));
  }

  strdData(){
    let cconfig = this._hoteldata.currentConfig.data;
    let cuestionario = this.Cuestionarios.find( c => { return c.id == cconfig.test.id} );

    if(!cuestionario) return;

    //Almacenar cambios
    let storeDB:storedTest = {
      id: cconfig.test.id,
      hotel_clave: cconfig.hotel.clave,
      visita: cuestionario.visita,
      secciones : cconfig.secciones,
      playing_score: cconfig.playing_score,
      limit_score: cconfig.limit_score,
      fecha: moment().format('DD[/]MM[/]YYYY'),
      usuario: cuestionario.usuario,
      preguntas: cconfig.preguntas
    }

    this.uploadData(storeDB);
  }

  resetPreguntas(){
    this._hoteldata.currentConfig.preguntas=[];
    this.Preguntas.forEach( pregunta=>{
      pregunta.checked = false;
      pregunta.marca = 0;
      pregunta.hora = "",
      pregunta.fecha = "",
      pregunta.capex = false,
      pregunta.notas = []
    })
    console.log("Preguntas reset succed!");
  }

  Preguntas:Array<questions> = [
    //ADMINISTRACIÓN
    {id:"1P1ADMN",seccion_clave:"1ADMN",checked:false,valor:1,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"La dureza del agua en cisterna de agua tratada no excede de 100 PPM"},
    {id:"2P1ADMN",seccion_clave:"1ADMN",checked:false,valor:1,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Se aplica programa de mantenimiento preventivo a equipos de aire acondicionado y extractores, se encuentra firmado por el Gerente de Mantenimiento y el operador quien realizo el servicio"},
    {id:"3P1ADMN",seccion_clave:"1ADMN",checked:false,valor:1,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"El cloro residual en cisternas de agua tratada se halla con valores entre 0.4 - 1.00 (revisar bitácora)"},
    {id:"4P1ADMN",seccion_clave:"1ADMN",checked:false,valor:1,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Se aplica programa de mantenimiento preventivo a equipos de cocina (Estufones, plancha, refrigeradores) , se encuentra firmado por el Gerente de Mantenimiento y el operador quien realizo el servicio y responsable de cocina"},
    {id:"5P1ADMN",seccion_clave:"1ADMN",checked:false,valor:1,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Se aplica programa de mantenimiento preventivo a subestación y transformadores , se encuentra firmado por el Gerente de Mantenimiento y el operador quien realizo el servicio"},
    {id:"6P1ADMN",seccion_clave:"1ADMN",checked:false,valor:1,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Se aplica programa de mantenimiento preventivo a equipos de cuarto de máquinas, se encuentra firmado por el Gerente de Mantenimiento y el operador quien realizo el servicio"},
    {id:"7P1ADMN",seccion_clave:"1ADMN",checked:false,valor:1,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Se aplica programa de mantenimiento preventivo a equipos de lavandería , se encuentra firmado por el Gerente de Mantenimiento, el operador quien realizo el servicio y por ama de llaves"},
    {id:"8P1ADMN",seccion_clave:"1ADMN",checked:false,valor:1,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Se lleva el programa de mantenimiento en las habitaciones de acuerdo a programa, política y sistemas de control de la marca,  y los formatos cuentan con la firma de recibido por parte de ama de llaves"},
    {id:"9P1ADMN",seccion_clave:"1ADMN",checked:false,valor:1,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Se lleva el programa de mantenimiento en las áreas públicas, centros de consumo, salones de eventos,  áreas administrativas y de servicio, las hojas de servicio están firmadas por el ama de llaves"},
    {id:"10P1ADMN",seccion_clave:"1ADMN",checked:false,valor:1,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Las tierras físicas del edificio y del sistema de pararrayos cuentan con reportes especializados (de menos de 1 año de antigüedad) de mediciones con lecturas menores a 10  Ω (ohms)."},
    {id:"11P1ADMN",seccion_clave:"1ADMN",checked:false,valor:1,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Existe el formato actualizado de control de gastos de inversión establecido para este propósito y hay en mantenimiento copia de los trabajos en proceso o terminados."},
    {id:"12P1ADMN",seccion_clave:"1ADMN",checked:false,valor:1,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Hay reporte de laboratorio de tratamiento de agua para las calderas."},
    {id:"13P1ADMN",seccion_clave:"1ADMN",checked:false,valor:1,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Las tierras físicas del conmutador y site de cómputo se hallan con reportes especializados (de menos de 1 año de antigüedad) de mediciones con lecturas menores a 3 Ω (ohms)"},
    {id:"14P1ADMN",seccion_clave:"1ADMN",checked:false,valor:1,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Los tanques de agua caliente cuentan con reporte de servicio de mantenimiento por el lado exterior y el interior con menos de un año de antigüedad y no hay trabajos pendientes por efectuar."},
    {id:"15P1ADMN",seccion_clave:"1ADMN",checked:false,valor:1,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Hay reporte de laboratorio (no más de un año de efectuado) de laboratorios de estado del carbón activado, antracita  y resina."},
    {id:"16P1ADMN",seccion_clave:"1ADMN",checked:false,valor:1,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Mostrar contrato vigente de mantenimiento para el conmutador telefónico, mantenimiento a chillers, mantenimiento ascensores y mantenimiento a sistema contra incendio"},
    {id:"17P1ADMN",seccion_clave:"1ADMN",checked:false,valor:1,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"El UPS (No break) y conmutador telefónico cuenta con reporte de servicio especializado con menos de un año de antigüedad y no hay trabajos pendientes por efectuar."},
    {id:"18P1ADMN",seccion_clave:"1ADMN",checked:false,valor:1,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Hay reporte de servicio por Cía. especializada en instalaciones eléctricas, fechado con menos de un año de antigüedad para el equipo de subestación eléctrica (cuchillas, apartarrayos, etc.) y transformadores y estos se hallan sin observaciones o trabajos por ejecutar./ Los  valores de las pruebas de transformadores se hallan dentro de parámetros de acuerdo a normatividad oficial."},
    {id:"19P1ADMN",seccion_clave:"1ADMN",checked:false,valor:2,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Hay reporte de servicio externo de limpieza de ductos de campanas de cocina (no mayor a un año de efectuado el servicio o antes si así lo requieren las regulaciones locales)."},
    {id:"20P1ADMN",seccion_clave:"1ADMN",checked:false,valor:1,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Los circuitos de tuberías de agua helada cuentan con tratamiento químico apropiado. Mostrar reporte de análisis químico del agua helada o factura de la aplicación del tratamiento."},
    {id:"21P1ADMN",seccion_clave:"1ADMN",checked:false,valor:1,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Los tableros eléctricos derivados cuentan con reporte de servicio de mantenimiento preventivo con menos de 1 año de antigüedad."},
    {id:"22P1ADMN",seccion_clave:"1ADMN",checked:false,valor:2,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Los chillers se hallan con mantenimiento adecuado y servicio por proveedor especializado cada 6 meses, dependiendo de las condiciones climáticas y existen reportes de este servicio, sin trabajos pendientes por efectuar."},
    {id:"23P1ADMN",seccion_clave:"1ADMN",checked:false,valor:2,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Transporte terrestre, mantenimiento, estado fisico de motor, llantas, puertas y luces."},

    //FACHADAS Y EXTERIOR DEL EDIFICIO
    {id:"1P2FCHE",seccion_clave:"2FCHE",checked:false,valor:0.5,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Estacionamiento: Iluminación, letreros liberación de responsabilidad"},
    {id:"2P2FCHE",seccion_clave:"2FCHE",checked:false,valor:1,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Estacionamiento : Piso/ Techo / Paredes/ Señalización/ 2 cajones para discapacitados"},
    {id:"3P2FCHE",seccion_clave:"2FCHE",checked:false,valor:0.5,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Monumental / Tapete / Tótems"},
    {id:"4P2FCHE",seccion_clave:"2FCHE",checked:false,valor:0.5,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Anuncio Espectacular / Lona / Iluminación "},
    {id:"5P2FCHE",seccion_clave:"2FCHE",checked:false,valor:1,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Fachada: Toldo/Techo-Entrada Principal"},
    {id:"6P2FCHE",seccion_clave:"2FCHE",checked:false,valor:1,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Fachada: Acabados y pintura"},
    {id:"7P2FCHE",seccion_clave:"2FCHE",checked:false,valor:0.5,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Marquesinas de acceso a lobby y salones de eventos"},
    {id:"8P2FCHE",seccion_clave:"2FCHE",checked:false,valor:1,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Mármol / Concreto- Motor lobby"},
    {id:"9P2FCHE",seccion_clave:"2FCHE",checked:false,valor:0.5,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Jardinería Gral. en buenas condiciones"},
    {id:"10P2FCHE",seccion_clave:"2FCHE",checked:false,valor:0.5,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Las luces de obstrucción en la azotea del edificio funcionan (deben estar encendidas de noche)"},
    {id:"11P2FCHE",seccion_clave:"2FCHE",checked:false,valor:1,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Impermeabilización"},
    {id:"12P2FCHE",seccion_clave:"2FCHE",checked:false,valor:1,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Toma siamesa con tapas completas y delimitada la zona de estacionamiento para el camión de bomberos"},

    //HABITACIONES
    {id:"1P3HBTS",seccion_clave:"3HBTS",checked:false,valor:0.5,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Juntas de hule en los marcos de las puertas.	"},
    {id:"2P3HBTS",seccion_clave:"3HBTS",checked:false,valor:0.5,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Acabados/ Funcionamiento del seguro 'dead bolt' en la puerta de acceso, bisagras y mirilla.	"},
    {id:"3P3HBTS",seccion_clave:"3HBTS",checked:false,valor:2,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Funcionamiento aire acondicionado y termostatos	"},
    {id:"4P3HBTS",seccion_clave:"3HBTS",checked:false,valor:1,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Paredes/Techo/ Piso/Alfombra/Mármol/ Ventanas	"},
    {id:"5P3HBTS",seccion_clave:"3HBTS",checked:false,valor:1,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Cómoda/Armario/ Buró de noche/Mesa de centro /Sillas /Sofá /Otomán/escritorio /Closet/ Cabecera/Base de Cama(s) Cama, Burro de planchar y planchas	"},
    {id:"6P3HBTS",seccion_clave:"3HBTS",checked:false,valor:1,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Colchones	"},
    {id:"7P3HBTS",seccion_clave:"3HBTS",checked:false,valor:1,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Equipo de Aire Acondicionado: Rejillas y estado de los filtros	"},
    {id:"8P3HBTS",seccion_clave:"3HBTS",checked:false,valor:1,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Radio Despertador/Teléfono/Televisión	"},
    {id:"9P3HBTS",seccion_clave:"3HBTS",checked:false,valor:1,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Iluminación 	"},
    {id:"10P3HBTS",seccion_clave:"3HBTS",checked:false,valor:0.5,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Espejos/Cuadros decorativos/ Lámparas de buró / Lámparas de pie / Arbotantes.	"},
    {id:"11P3HBTS",seccion_clave:"3HBTS",checked:false,valor:1,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Estado de carretillas y cortineros en puertas y ventanas	"},
    {id:"12P3HBTS",seccion_clave:"3HBTS",checked:false,valor:1,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Cortinas: Frescura/ Black Out / Decorativa	"},
    {id:"13P3HBTS",seccion_clave:"3HBTS",checked:false,valor:2,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Detectores de humo operando (verificar pila de respaldo)	"},
    {id:"14P3HBTS",seccion_clave:"3HBTS",checked:false,valor:2,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Existen rociadores en buenas condiciones en todas las habitaciones.	"},
    {id:"15P3HBTS",seccion_clave:"3HBTS",checked:false,valor:1,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Una habitación para discapacitados según estándar por cada 200 cuartos.	"},

    //BAÑOS  DE HABITACIONES
    {id:"1P4BNHB",seccion_clave:"4BNHB",checked:false,valor:1,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Iluminación "},
    {id:"2P4BNHB",seccion_clave:"4BNHB",checked:false,valor:1,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Baño de discapacitados de acuerdo a estándares"},
    {id:"3P4BNHB",seccion_clave:"4BNHB",checked:false,valor:1,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Área regadera/tina/ W.C./ Tubo y cortina de baño / cromos/ Tapón de tina"},
    {id:"4P4BNHB",seccion_clave:"4BNHB",checked:false,valor:1,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Condiciones: Plafón / Pintura / Rejillas de baño / Luminarias."},
    {id:"5P4BNHB",seccion_clave:"4BNHB",checked:false,valor:1,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Secadora de cabello de 1500 watts."},
    {id:"6P4BNHB",seccion_clave:"4BNHB",checked:false,valor:1,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Lavabo / Toalleros / Accesorios."},
    {id:"7P4BNHB",seccion_clave:"4BNHB",checked:false,valor:1,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Espejo de vanity / Cuerpo entero"},
    {id:"8P4BNHB",seccion_clave:"4BNHB",checked:false,valor:1,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Área de closet"},
    {id:"9P4BNHB",seccion_clave:"4BNHB",checked:false,valor:1,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Acabado  y condiciones: Pisos/ Paredes/Puertas/Ventanas"},
    {id:"10P4BNHB",seccion_clave:"4BNHB",checked:false,valor:2,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Agua caliente en el baño máximo 30 seg. En regadera y lavabo."},

    //PASILLOS DE HABITACIONES
    {id:"1P5PSHB",seccion_clave:"5PSHB",checked:false,valor:1,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Piso/Alfombra/Mármol	"},
    {id:"2P5PSHB",seccion_clave:"5PSHB",checked:false,valor:2,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Las escaleras de emergencia se hallan sin materiales, mobiliario, etc. que puedan obstaculizar el paso.	"},
    {id:"3P5PSHB",seccion_clave:"5PSHB",checked:false,valor:1,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Persianas/Cortinas/Señalización-Pasillos	"},
    {id:"4P5PSHB",seccion_clave:"5PSHB",checked:false,valor:1,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Roperías/ Muros/ Pisos/ Iluminación	"},
    {id:"5P5PSHB",seccion_clave:"5PSHB",checked:false,valor:1,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Plafón / Iluminación / Rejillas -Pasillos	"},
    {id:"6P5PSHB",seccion_clave:"5PSHB",checked:false,valor:1,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Máquinas con disponibilidad de hielo  en edificios verticales 1 máquina por cada 2 pisos. En edificios horizontales 1 máquina por cada 50 habitaciones.	"},
    {id:"7P5PSHB",seccion_clave:"5PSHB",checked:false,valor:1,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Las cubicadoras de pasillos se encuentran en buenas condiciones de operación y funcionamiento	"},
    {id:"8P5PSHB",seccion_clave:"5PSHB",checked:false,valor:1,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Obras de Arte/Espejos/Mobiliario/Accesorios/Plantas-Pasillos	"},
    {id:"9P5PSHB",seccion_clave:"5PSHB",checked:false,valor:2,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Todos los elevadores se hallan funcionando sin fallas.	"},
    {id:"10P5PSHB",seccion_clave:"5PSHB",checked:false,valor:1,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Paredes / Puertas / Tablero de Control / Señalización- Elevador y Cabina de los elevadores	"},
    {id:"11P5PSHB",seccion_clave:"5PSHB",checked:false,valor:1,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Piso / Alfombra- Cabina de los elevadores	"},
    {id:"12P5PSHB",seccion_clave:"5PSHB",checked:false,valor:0.5,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Las fábricas de hielo cuentan con filtros y estos cuentan con etiqueta de fecha de cambio no mayor a 6 meses de antigüedad	"},
    {id:"13P5PSHB",seccion_clave:"5PSHB",checked:false,valor:0.5,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Paredes/Puertas/Ventanas/Persianas/Cortinas/Señalización-Pasillos	"},

    //LOBBY RECEPCION
    {id:"1P6LBRC",seccion_clave:"6LBRC",checked:false,valor:1,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Persianas / Cortinas / Señalización de lobby"},
    {id:"2P6LBRC",seccion_clave:"6LBRC",checked:false,valor:1,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Piso/alfombra/Techo/Rejillas/Luces"},
    {id:"3P6LBRC",seccion_clave:"6LBRC",checked:false,valor:1,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Paredes/Puertas/Ventanas/"},
    {id:"4P6LBRC",seccion_clave:"6LBRC",checked:false,valor:1,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Techo: Plafón/Rejillas/Iluminación."},
    {id:"5P6LBRC",seccion_clave:"6LBRC",checked:false,valor:1,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Mobiliario"},
    {id:"6P6LBRC",seccion_clave:"6LBRC",checked:false,valor:1,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Cuarto de equipaje/Carros de equipaje"},
    {id:"7P6LBRC",seccion_clave:"6LBRC",checked:false,valor:1,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Jardineras / Macetas / Plantas"},
    {id:"8P6LBRC",seccion_clave:"6LBRC",checked:false,valor:1,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Front Desk: Acabados / Mobiliario"},


    //BAÑOS PÚBLICOS
    {id:"1P7BNPB",seccion_clave:"7BNPB",checked:false,valor:0.5,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Techo: Plafón/Rejillas/Iluminación.	"},
    {id:"2P7BNPB",seccion_clave:"7BNPB",checked:false,valor:0.5,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Paredes/Puertas/Chapas/ Ventanas	"},
    {id:"3P7BNPB",seccion_clave:"7BNPB",checked:false,valor:1,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Pisos mármol/ loseta cerámica/ otros	"},
    {id:"4P7BNPB",seccion_clave:"7BNPB",checked:false,valor:0.5,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Cubículos: Acabados/ Percheros/ accesorios	"},
    {id:"5P7BNPB",seccion_clave:"7BNPB",checked:false,valor:1,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Lavabos / Llaves electrónicas / Ovalines / Desagües .	"},
    {id:"6P7BNPB",seccion_clave:"7BNPB",checked:false,valor:1,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Inodoros/ Mingitorios/ Fluxómetros/ Lavabos / Llaves / Cubierta / Espejos	"},
    {id:"7P7BNPB",seccion_clave:"7BNPB",checked:false,valor:1,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Baños  para discapacitados  de acuerdo a estándar	"},

    //CENTRO DE NEGOCIOS.
    {id:"1P8CENG",seccion_clave:"8CENG",checked:false,valor:0.5,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Paredes/Puertas/Ventanas.	"},
    {id:"2P8CENG",seccion_clave:"8CENG",checked:false,valor:0.5,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Piso/alfombra/mármol	"},
    {id:"3P8CENG",seccion_clave:"8CENG",checked:false,valor:0.5,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Plafón/Rejillas/Iluminación.	"},
    {id:"4P8CENG",seccion_clave:"8CENG",checked:false,valor:1,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Mobiliario área Gral. y salas de juntas	"},
    {id:"5P8CENG",seccion_clave:"8CENG",checked:false,valor:1,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Funcionamiento aire acondicionado y termostatos	"},

    //SALA DE JUNTAS
    {id:"1P9SLJS",seccion_clave:"9SLJS",checked:false,valor:0.5,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Paredes/Puertas/Ventanas.	"},
    {id:"2P9SLJS",seccion_clave:"9SLJS",checked:false,valor:0.5,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Piso/alfombra/mármol	"},
    {id:"3P9SLJS",seccion_clave:"9SLJS",checked:false,valor:0.5,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Plafón/Rejillas/Iluminación.	"},
    {id:"4P9SLJS",seccion_clave:"9SLJS",checked:false,valor:1,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Mobiliario área Gral. y salas de juntas	"},
    {id:"5P9SLJS",seccion_clave:"9SLJS",checked:false,valor:1,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Funcionamiento aire acondicionado y termostatos	"},

    //GIMNASIO
    {id:"1P10GMS",seccion_clave:"10GMS",checked:false,valor:0.5,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Paredes/Puertas/Chapas/ Ventanas	"},
    {id:"2P10GMS",seccion_clave:"10GMS",checked:false,valor:0.5,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Plafón/Rejillas/Iluminación.	"},
    {id:"3P10GMS",seccion_clave:"10GMS",checked:false,valor:1,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Pisos: Condiciones	"},
    {id:"4P10GMS",seccion_clave:"10GMS",checked:false,valor:1,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Aparatos de ejercicio.	"},
    {id:"5P10GMS",seccion_clave:"10GMS",checked:false,valor:0.5,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Equipos de TV	"},
    {id:"6P10GMS",seccion_clave:"10GMS",checked:false,valor:0.5,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Señalización y reglamento del gimnasio	"},
    {id:"7P10GMS",seccion_clave:"10GMS",checked:false,valor:1,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Funcionamiento aire acondicionado y termostatos	"},
    {id:"8P10GMS",seccion_clave:"10GMS",checked:false,valor:1,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Área de casilleros/ Vestidores	"},
    {id:"9P10GMS",seccion_clave:"10GMS",checked:false,valor:1,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Regaderas/W.C /Cubículos-Vestidores	"},
    {id:"10P10GMS",seccion_clave:"10GMS",checked:false,valor:1,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Área de sauna, vapor y regaderas separadas, en vestidores de hombres y mujeres .	"},

    //ALBERCA
    {id:"1P11ALB",seccion_clave:"11ALB",checked:false,valor:1,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Acabados en interior de la alberca	"},
    {id:"2P11ALB",seccion_clave:"11ALB",checked:false,valor:0.5,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Señales de profundidad en el borde y otras en las paredes interiores de la alberca s y letreros de 'NO CLAVADOS'	"},
    {id:"3P11ALB",seccion_clave:"11ALB",checked:false,valor:0.5,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"El reglamento de alberca y señalamientos del área se hallan en buenas condiciones	"},
    {id:"4P11ALB",seccion_clave:"11ALB",checked:false,valor:0.5,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Condiciones: Piso perimetral a la alberca.	"},
    {id:"5P11ALB",seccion_clave:"11ALB",checked:false,valor:1,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Camastros / Sillas / Mesas / Sombrillas.	"},
    {id:"6P11ALB",seccion_clave:"11ALB",checked:false,valor:0.5,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Club Fiesta Kids:  Plafón/ Paredes/Puertas/Ventanas	"},
    {id:"7P11ALB",seccion_clave:"11ALB",checked:false,valor:0.5,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Condiciones de la playa (donde aplique), jardines y señalización.	"},
    {id:"8P11ALB",seccion_clave:"11ALB",checked:false,valor:0.5,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Hay rejillas antivortex en las succiones de fondo de las albercas	"},
    {id:"9P11ALB",seccion_clave:"11ALB",checked:false,valor:1,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Calentador de alberca	"},

    //LOBBY BAR / ISLA
    {id:"1P12LBR",seccion_clave:"12LBR",checked:false,valor:1,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Condiciones: Refrigeradores y equipo en barra.	"},
    {id:"2P12LBR",seccion_clave:"12LBR",checked:false,valor:1,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Televisores: Condiciones/ Funcionamiento	"},
    {id:"3P12LBR",seccion_clave:"12LBR",checked:false,valor:1,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Paredes/Puertas/Ventanas	"},
    {id:"4P12LBR",seccion_clave:"12LBR",checked:false,valor:1,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Piso/alfombra/mármol	"},
    {id:"5P12LBR",seccion_clave:"12LBR",checked:false,valor:1,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Techo: Plafón/Rejillas/Iluminación.	"},
    {id:"6P12LBR",seccion_clave:"12LBR",checked:false,valor:1,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Mobiliario / Mesas / Sillas / Sillones/Mostrador.	"},
    {id:"7P12LBR",seccion_clave:"12LBR",checked:false,valor:1,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Persianas/Cortinas	"},
    {id:"8P12LBR",seccion_clave:"12LBR",checked:false,valor:1,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Funcionamiento aire acondicionado y termostatos	"},

    //RESTAURANTE
    {id:"1P13RST",seccion_clave:"13RST",checked:false,valor:1,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Televisores: Condiciones/ Funcionamiento	"},
    {id:"2P13RST",seccion_clave:"13RST",checked:false,valor:0.5,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Estaciones de servicio/área cajero	"},
    {id:"3P13RST",seccion_clave:"13RST",checked:false,valor:1,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Mobiliario (sillas/  mesas/  estaciones de servicio/ otros)	"},
    {id:"4P13RST",seccion_clave:"13RST",checked:false,valor:0.5,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Funcionamiento aire acondicionado y termostatos	"},
    {id:"5P13RST",seccion_clave:"13RST",checked:false,valor:0.5,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Chaffing dishes: Funcionamiento.	"},
    {id:"6P13RST",seccion_clave:"13RST",checked:false,valor:1,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Acabados : Barra de buffet/ Chaffing dishes	"},
    {id:"7P13RST",seccion_clave:"13RST",checked:false,valor:1,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Estufa / Plancha / Mesa fría / Refrigerador en área Huevera	"},
    {id:"8P13RST",seccion_clave:"13RST",checked:false,valor:0.5,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Cortineros/Persianas/Cortinas	"},
    {id:"9P13RST",seccion_clave:"13RST",checked:false,valor:0.5,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Plafón / Pintura / Iluminación / Rejillas.	"},
    {id:"10P13RST",seccion_clave:"13RST",checked:false,valor:0.5,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Jardineras / Macetas / Plantas.	"},
    {id:"11P13RST",seccion_clave:"13RST",checked:false,valor:0.5,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Piso/Alfombra/Mármol	"},
    {id:"12P13RST",seccion_clave:"13RST",checked:false,valor:0.5,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Paredes/Puertas/Ventanas	"},
    {id:"13P13RST",seccion_clave:"13RST",checked:false,valor:2,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"La campana de área huevera cuenta con sistema contra incendio tipo Ansul y éste se haya activado . No presenta cochambre	"},

    //SALONES
    {id:"1P14SLN",seccion_clave:"14SLN",checked:false,valor:1,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Paredes/Mamparas/Puertas/Ventanas	"},
    {id:"2P14SLN",seccion_clave:"14SLN",checked:false,valor:1,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Pisos: Alfombra/ mármol/ madera/ Otros	"},
    {id:"3P14SLN",seccion_clave:"14SLN",checked:false,valor:1,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Techo: Plafón/Rejillas/Iluminación.	"},
    {id:"4P14SLN",seccion_clave:"14SLN",checked:false,valor:1,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Mobiliario (sillas/  mesas/  estaciones de servicio/ otros)	"},
    {id:"5P14SLN",seccion_clave:"14SLN",checked:false,valor:0.5,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Apagadores / Contactos / Dimmers / Pantallas.	"},
    {id:"6P14SLN",seccion_clave:"14SLN",checked:false,valor:1,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Funcionamiento aire acondicionado y termostatos	"},
    {id:"7P14SLN",seccion_clave:"14SLN",checked:false,valor:0.5,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Señalización y salidas de emergencia iluminadas	"},

    //FOYER
    {id:"1P15FYR",seccion_clave:"15FYR",checked:false,valor:1,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Paredes/ Puertas/ Ventanas	"},
    {id:"2P15FYR",seccion_clave:"15FYR",checked:false,valor:1,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Pisos: Alfombra/ mármol/ madera/ Otros	"},
    {id:"3P15FYR",seccion_clave:"15FYR",checked:false,valor:1,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Techo: Plafón/Rejillas/Iluminación.	"},
    {id:"4P15FYR",seccion_clave:"15FYR",checked:false,valor:1,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Mobiliario (sillones / mesas/ otros)	"},
    {id:"5P15FYR",seccion_clave:"15FYR",checked:false,valor:0.5,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Accesorios: Apagadores/ contactos/ otros.	"},
    {id:"6P15FYR",seccion_clave:"15FYR",checked:false,valor:1,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Funcionamiento aire acondicionado y termostatos"},

    //BACK OF THE HOUSE
    {id:"1P16BOH",seccion_clave:"16BOH",checked:false,valor:0.5,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Vestidores/Baños Colaboradores	"},
    {id:"2P16BOH",seccion_clave:"16BOH",checked:false,valor:0.5,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Oficinas Administrativas y Ejecutivas	"},
    {id:"3P16BOH",seccion_clave:"16BOH",checked:false,valor:1,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Roperías	"},
    {id:"4P16BOH",seccion_clave:"16BOH",checked:false,valor:1,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Comedor de colaboradores	"},
    {id:"5P16BOH",seccion_clave:"16BOH",checked:false,valor:1,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Hay agua caliente en las regaderas de baños de colaboradores	"},

    //COCINA
    {id:"1P17CNA",seccion_clave:"17CNA",checked:false,valor:2,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Condiciones: Rociadores del sistema contra incendio.	"},
    {id:"2P17CNA",seccion_clave:"17CNA",checked:false,valor:1,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"El sistema Ansul (o de otra marca) de protección a campanas se haya habilitado, boquillas orientadas a los equipos y cuentan con capuchones.	"},
    {id:"3P17CNA",seccion_clave:"17CNA",checked:false,valor:1,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Las trampas de grasa cuentan con peines y están trabajando correctamente	"},
    {id:"4P17CNA",seccion_clave:"17CNA",checked:false,valor:0.5,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Alumbrado completo, en buenas condiciones y cuenta con protección acrílica.	"},
    {id:"5P17CNA",seccion_clave:"17CNA",checked:false,valor:1,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Campanas de cocina limpias, con filtros y alumbrado completos.	"},
    {id:"6P17CNA",seccion_clave:"17CNA",checked:false,valor:1,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Hornos rotativos, otros hornos, lámparas infrarrojas	"},
    {id:"7P17CNA",seccion_clave:"17CNA",checked:false,valor:1,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"La freidora cuenta con todo su equipo completo   (termostato o válvula pilostática de seguridad) y en buenas condiciones.	"},
    {id:"8P17CNA",seccion_clave:"17CNA",checked:false,valor:0.5,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Almacenes de mercancía : Acabados/ Anaqueles/ Iluminación.	"},
    {id:"9P17CNA",seccion_clave:"17CNA",checked:false,valor:0.5,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Empaques de los refrigeradores y congeladores en buenas condiciones.	"},
    {id:"10P17CNA",seccion_clave:"17CNA",checked:false,valor:1,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Salamandra, rebanadoras, licuadora industrial y cafeteras .	"},
    {id:"11P17CNA",seccion_clave:"17CNA",checked:false,valor:0.5,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Paredes/ Puertas/ Ventanas	"},
    {id:"12P17CNA",seccion_clave:"17CNA",checked:false,valor:0.5,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Plafón / Pintura / Iluminación / Rejillas / Lámparas.	"},
    {id:"13P17CNA",seccion_clave:"17CNA",checked:false,valor:1,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Funcionamiento y condiciones de máquina lavaloza. Temperatura mínima 120°F lavado y enjuague	"},
    {id:"14P17CNA",seccion_clave:"17CNA",checked:false,valor:0.5,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Los filtros de agua de las cafeteras y de fábricas de hielo se reemplazan cada 6 meses o según instrucciones del fabricante (verificar si tienen etiqueta con fecha de instalación marcada)	"},
    {id:"15P17CNA",seccion_clave:"17CNA",checked:false,valor:0.5,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Estado físico y funcionamiento de los lavabos en área de cocina.	"},
    {id:"16P17CNA",seccion_clave:"17CNA",checked:false,valor:1,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Acabado de pisos / rejillas/ coladeras	"},
    {id:"17P17CNA",seccion_clave:"17CNA",checked:false,valor:2,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Los congeladores tipo ropero y/o  horizontales de la cocina mantienen a los productos en una temperatura de -18°C a -20°C.	"},
    {id:"18P17CNA",seccion_clave:"17CNA",checked:false,valor:1,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Las estufas, planchas y parrillas se encuentran con sus gabinetes, partes y accesorios completos, en buenas condiciones de operación.	"},
    {id:"19P17CNA",seccion_clave:"17CNA",checked:false,valor:2,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"La válvula de seguridad de corte de gas en la tubería de equipos en zona de cocción se haya funcionando y en buenas condiciones	"},
    {id:"20P17CNA",seccion_clave:"17CNA",checked:false,valor:1,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Extractores de jugos, marmitas de gas , procesador de vegetales, procesador de alimentos, enfriador de botellas, batidoras, tostador para pan, waffleras, molino de carne.	"},
    {id:"21P17CNA",seccion_clave:"17CNA",checked:false,valor:1,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"El horno de convección se halla funcionando y sus controles de seguridad completos (control de temperatura, encendido electrónico con sistema de seguridad para gas y flama, timer, vidrios refractarios)	"},
    {id:"22P17CNA",seccion_clave:"17CNA",checked:false,valor:2,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Existen mínimo 2 extintores tipo 'K' o de  CO2 contra incendio adecuados para fuegos en cocinas	"},
    {id:"23P17CNA",seccion_clave:"17CNA",checked:false,valor:0.5,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Área de recepción de mercancía	"},

    //CÁMARAS FRIAS
    {id:"1P18CFR",seccion_clave:"18CFR",checked:false,valor:0.5,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Los acabados de las cámaras frías (pisos, muros y techos) y el alumbrado están en buenas condiciones.	"},
    {id:"2P18CFR",seccion_clave:"18CFR",checked:false,valor:0.5,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Las puertas de las cámaras tienen bota seguros interiores en las chapas y estos se hayan en buenas condiciones.	"},
    {id:"3P18CFR",seccion_clave:"18CFR",checked:false,valor:1,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Los empaques de las puertas de cámaras frías, así como cortinas hawaianas se hayan en buenas condiciones.	"},
    {id:"4P18CFR",seccion_clave:"18CFR",checked:false,valor:0.5,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Las resistencias eléctricas de las puertas  ( los empaques se hayan tibios al tacto) de las cámaras de congelación funcionan correctamente.	"},
    {id:"5P18CFR",seccion_clave:"18CFR",checked:false,valor:2,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Las cámaras de congelación operan a  una temperatura interior de -18°c a - 20°C	"},
    {id:"6P18CFR",seccion_clave:"18CFR",checked:false,valor:2,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Las cámaras frías de conservación operan a una temperatura interior de +2°C  a +4°C.	"},

    //AIRE ACONDICIONADO / REFRIGERACIÓN
    {id:"1P19ARF",seccion_clave:"19ARF",checked:false,valor:0.5,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Sistema de calefacción de las UMAS	"},
    {id:"2P19ARF",seccion_clave:"19ARF",checked:false,valor:0.5,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Los modutroles de las UMAS funcionan correctamente tanto en frío como en calefacción. Hay termómetros y manómetros en entrada y salida de agua helada	"},
    {id:"3P19ARF",seccion_clave:"19ARF",checked:false,valor:1,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Las UMAS se hallan con filtros limpios, ductos y aislamientos en buen estado  y funcionando normalmente.	"},
    {id:"4P19ARF",seccion_clave:"19ARF",checked:false,valor:1,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Los inyectores y extractores de aire funcionan sin fallas y se encuentran en buenas condiciones .	"},
    {id:"5P19ARF",seccion_clave:"19ARF",checked:false,valor:1,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Condiciones: Ductos sistema de A/A y aislamientos de tuberías.	"},
    {id:"6P19ARF",seccion_clave:"19ARF",checked:false,valor:1,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Las torres de enfriamiento (si existen) se hallan trabajando y sus componentes están en buenas condiciones	"},
    {id:"7P19ARF",seccion_clave:"19ARF",checked:false,valor:1,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Unidades de aire acondicionado tipo paquete, se hallan todas funcionando con mantenimiento adecuado	"},
    {id:"8P19ARF",seccion_clave:"19ARF",checked:false,valor:2,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Los chillers se hallan funcionando bien y están en buenas condiciones de operación.	"},
    {id:"9P19ARF",seccion_clave:"19ARF",checked:false,valor:0.5,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Los chillers cuentan con termómetros y manómetros en entrada y salida de agua helada y estos funcionan apropiadamente.	"},
    {id:"10P19ARF",seccion_clave:"19ARF",checked:false,valor:1,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Las bombas del sistema de condensación se hallan funcionando y en buenas condiciones	"},
    {id:"11P19ARF",seccion_clave:"19ARF",checked:false,valor:1,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"El intercambiador de calor del chiller (si es que cuenta con este equipo) está funcionando (tanque de precalentamiento, bomba recirculadora y válvulas abiertas)	"},
    {id:"12P19ARF",seccion_clave:"19ARF",checked:false,valor:2,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Funcionamiento y buenas condiciones de las bombas de agua helada	"},

    //INSTALACIÓN ELÉCTRICA
    {id:"1P20IEL",seccion_clave:"20IEL",checked:false,valor:0.5,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Los interruptores tipo navajas para inyectores y extractores cuentan con fusibles y estos no se hallan 'puenteados' (si esto aplica).	"},
    {id:"2P20IEL",seccion_clave:"20IEL",checked:false,valor:2,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"La planta de emergencia se halla funcionando en automático , en buenas condiciones de operación (instrumentación, baterías, etc.).	"},
    {id:"3P20IEL",seccion_clave:"20IEL",checked:false,valor:0.5,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Existen tarimas fibra de vidrio o de madera sin clavos de fierro en el piso del área de alta tensión	"},
    {id:"4P20IEL",seccion_clave:"20IEL",checked:false,valor:2,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"El precalentador del generador de emergencia se halla en operación	"},
    {id:"5P20IEL",seccion_clave:"20IEL",checked:false,valor:1,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"El sistema de pararrayos (puntas, cableado  y sus componentes) se encuentra en buenas condiciones físicas y de funcionamiento.	"},
    {id:"6P20IEL",seccion_clave:"20IEL",checked:false,valor:1,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Los tableros derivados se hallan limpios,  identificados, tapas completas y los interruptores coinciden con los circuitos que se marcan.	"},

    //LAVANDERÍA
    {id:"1P21LVD",seccion_clave:"21LVD",checked:false,valor:0.5,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"1.- Estado físico y funcionamiento de lavadoras. 	"},
    {id:"2P21LVD",seccion_clave:"21LVD",checked:false,valor:0.5,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"2.- Estado físico y funcionamiento de secadoras. 	"},
    {id:"3P21LVD",seccion_clave:"21LVD",checked:false,valor:0.5,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"3.- Estado físico y funcionamiento de mangle. 	"},
    {id:"4P21LVD",seccion_clave:"21LVD",checked:false,valor:0.5,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"4.- Estado físico y funcionamiento de equipo tintorería. 	"},
    {id:"5P21LVD",seccion_clave:"21LVD",checked:false,valor:0.5,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"5.- Limpieza y acabados (pisos, techos y muros).	"},
    {id:"6P21LVD",seccion_clave:"21LVD",checked:false,valor:0.5,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"6.- Inyección y extracción de aire en el local.	"},
    {id:"7P21LVD",seccion_clave:"21LVD",checked:false,valor:1,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"8.- Limpieza de ductos de extracción (mostrar orden de trabajo)	"},
    {id:"8P21LVD",seccion_clave:"21LVD",checked:false,valor:0.5,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Techo/ paredes/ alumbrado	"},

    //SUBESTACIÓN ELÉCTRICA
    {id:"1P22SEL",seccion_clave:"22SEL",checked:false,valor:1,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"1.- El funcionamiento y programa de mantenimiento preventivo de la subestación se encuentra actualizado y cumpliendo con normas vigentes y recomendaciones del fabricante.(mostrar comprobantes de servicio).	"},
    {id:"2P22SEL",seccion_clave:"22SEL",checked:false,valor:1,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"2.- Comprobante de la última prueba efectuada de rigidez dieléctrica y BPCs llevadas a cabo a los transformadores, así como del último servicio llevado a cabo.	"},
    {id:"3P22SEL",seccion_clave:"22SEL",checked:false,valor:0.5,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"3.- Hay en existencia cuando menos un fusible de alta tensión de la capacidad necesaria para resolver una emergencia.	"},
    {id:"4P22SEL",seccion_clave:"22SEL",checked:false,valor:2,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"4.- Mediciones y buen funcionamiento de red de todas las tierras.	"},
    {id:"5P22SEL",seccion_clave:"22SEL",checked:false,valor:1,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"5.- Operan adecuadamente los capacitores y el factor de potencia es superior al 90%.	"},

    //CASAS DE MÁQUINAS
    {id:"1P23CMQ",seccion_clave:"23CMQ",checked:false,valor:0.5,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Estado físico de cisternas, limpias y sin fisuras o agrietamientos.	"},
    {id:"2P23CMQ",seccion_clave:"23CMQ",checked:false,valor:0.5,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Alumbrado casas de máquinas (100 luxes mínimo)	"},
    {id:"3P23CMQ",seccion_clave:"23CMQ",checked:false,valor:0.5,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"La casa de máquinas de ascensores cuenta con equipo de aire acondicionado en lugares cálidos.	"},
    {id:"4P23CMQ",seccion_clave:"23CMQ",checked:false,valor:0.5,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"La casa de máquinas en donde se hallan alojados los tanques de agua caliente, calentadores, sistema de bombeo, etc. se halla en buenas condiciones (piso, techo, muros, pintura y señalización)	"},
    {id:"5P23CMQ",seccion_clave:"23CMQ",checked:false,valor:2,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Los filtros de arena, carbón activado y resina , así como el tanque de salmuera se hallan en buenas condiciones y trabajando de acuerdo a instrucciones del fabricante (solicitar que los pongan a funcionar).	"},
    {id:"6P23CMQ",seccion_clave:"23CMQ",checked:false,valor:1,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Las válvulas principales en todas las instalaciones están operables en buenas condiciones y cuentan con tarjeta de identificación.	"},
    {id:"7P23CMQ",seccion_clave:"23CMQ",checked:false,valor:0.5,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Los tanques precargados (si los hay) tienen las membranas en buenas condiciones.	"},
    {id:"8P23CMQ",seccion_clave:"23CMQ",checked:false,valor:1,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"El equipo de máquinas desalinizadoras (si las hay) se encuentra trabajando sin fallas.	"},
    {id:"9P23CMQ",seccion_clave:"23CMQ",checked:false,valor:0.5,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Existe medidor de gas independiente al medidor de los tanques.	"},
    {id:"10P23CMQ",seccion_clave:"23CMQ",checked:false,valor:0.5,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"todas las bombas de recirculación están funcionando y en buenas condiciones.	"},
    {id:"11P23CMQ",seccion_clave:"23CMQ",checked:false,valor:0.5,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Los tanques de agua caliente cuentan con forros , termómetros, acuastato, válvula de alivio y válvula de seguridad en buen estado.	"},
    {id:"12P23CMQ",seccion_clave:"23CMQ",checked:false,valor:0.5,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Los tanques de agua caliente tienen una temperatura entre 42°C y 50°C. (107.6 y 122.0°F)	"},
    {id:"13P23CMQ",seccion_clave:"23CMQ",checked:false,valor:0.5,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Estado externo e interno de tanques de agua caliente, mostrar reportes y / o comprobantes del último servicio.	"},
    {id:"14P23CMQ",seccion_clave:"23CMQ",checked:false,valor:0.5,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Los ascensores se hallan limpios y sus acabados de piso/techo/muros en buen estado y funcionamiento	"},
    {id:"15P23CMQ",seccion_clave:"23CMQ",checked:false,valor:1,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Equipo de filtrado de la alberca	"},
    {id:"16P23CMQ",seccion_clave:"23CMQ",checked:false,valor:2,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"El sistema hidroneumático funciona bien y en condiciones normales (tanques, bombas, tableros de control) así como sus accesorios).	"},
    {id:"17P23CMQ",seccion_clave:"23CMQ",checked:false,valor:1,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"El sistema de bombeo de trasvase funciona bien y en condiciones normales (tanques, bombas, tableros de control y accesorios)	"},
    {id:"18P23CMQ",seccion_clave:"23CMQ",checked:false,valor:2,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Las tuberias de gas natural en buenas condiciones.	"},
    {id:"19P23CMQ",seccion_clave:"23CMQ",checked:false,valor:1,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"El mantenimiento preventivo de calentadores de agua y / o calderas se encuentra al día y los equipos funcionando correctamente. Verificar flamas.	"},
    {id:"20P23CMQ",seccion_clave:"23CMQ",checked:false,valor:0.5,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Limpieza y acabados (pisos, techos y muros).	"},

    //CONTROL DE RIESGOS
    {id:"1P24CRG",seccion_clave:"24CRG",checked:false,valor:1,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Las casas de máquinas cuentan con sistema de rociadores.	"},
    {id:"2P24CRG",seccion_clave:"24CRG",checked:false,valor:0.5,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Se cuenta con almacenes de productos químicos, pinturas, residuos peligrosos y residuos no peligrosos	"},
    {id:"3P24CRG",seccion_clave:"24CRG",checked:false,valor:0.5,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Están debidamente señalizados los diques, tierras físicas de tanques de almacenamiento de combustibles y equipo contra incendio.	"},
    {id:"4P24CRG",seccion_clave:"24CRG",checked:false,valor:0.5,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Todos los hidrantes cuentan con llave spangler para acoplamiento de mangueras.	"},
    {id:"5P24CRG",seccion_clave:"24CRG",checked:false,valor:1,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"La casa de máquinas de ascensores cuenta con detectores de humo supervisados a tablero principal	"},
    {id:"6P24CRG",seccion_clave:"24CRG",checked:false,valor:1,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Hay rociador instalado en la parte superior del chute de ropa sucia.	"},
    {id:"7P24CRG",seccion_clave:"24CRG",checked:false,valor:0.5,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Letreros de SALIDA o EXIT emergencia iluminados	"},
    {id:"8P24CRG",seccion_clave:"24CRG",checked:false,valor:1,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Las puertas del chute de ropa sucia se hallan cerradas mientras no se está descargando ropa en los pisos de habitaciones	"},
    {id:"9P24CRG",seccion_clave:"24CRG",checked:false,valor:1,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"La descarga del chute de ropa cuenta con compuerta metálica.	"},
    {id:"10P24CRG",seccion_clave:"24CRG",checked:false,valor:1,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Las puertas de la lavandería son resistentes al fuego 1 hr. y se encuentran cerradas.	"},
    {id:"11P24CRG",seccion_clave:"24CRG",checked:false,valor:2,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"La presión mínima en el hidrante más lejano es de 65 PSI en instalación con rociadores y de 100 PSI si no hay rociadores en el hotel (verificar presión en el cuadro de válvulas más lejano)	"},
    {id:"12P24CRG",seccion_clave:"24CRG",checked:false,valor:1,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Los gabinetes de hidrantes contra incendio se encuentran limpios, con vidrios completos y se hallan debidamente señalizados/ Las mangueras se hallan en su sitio y cuentan con sus respectivos chiflones	"},
    {id:"13P24CRG",seccion_clave:"24CRG",checked:false,valor:1,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"La tubería del raiser cuenta con detectores de flujo y válvulas supervisadas conectadas en cada nivel de edificio.	"},
    {id:"14P24CRG",seccion_clave:"24CRG",checked:false,valor:2,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Todos los extintores se hallan vigentes en sus cargas.	"},
    {id:"15P24CRG",seccion_clave:"24CRG",checked:false,valor:0.5,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Existe equipo de respiración autónoma	"},
    {id:"16P24CRG",seccion_clave:"24CRG",checked:false,valor:2,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Todas las válvulas principales del sistema contra incendio se hallan eléctricamente supervisadas o con cadena de plástico.	"},
    {id:"17P24CRG",seccion_clave:"24CRG",checked:false,valor:2,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"El equipo de bombeo contra incendio arranca en automático (ver que hagan prueba de arranque).	"},
    {id:"18P24CRG",seccion_clave:"24CRG",checked:false,valor:1,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"La vitrina del equipo contra incendio se halla completa con cuatro uniformes completos, además de palas, cascos, botas, picos, palas, lámparas de baterías con pilas cargadas.	"},
    {id:"19P24CRG",seccion_clave:"24CRG",checked:false,valor:2,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"El cuarto del conmutador , equipos de TV y site cuenta con sistema de protección contra incendio (rociadores o detectores de humo) y extintores adecuados	"},
    {id:"20P24CRG",seccion_clave:"24CRG",checked:false,valor:1,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Las luces de obstrucción en la azotea del edificio funcionan adecuadamente (deben estar encendidas de noche)	"},
    {id:"21P24CRG",seccion_clave:"24CRG",checked:false,valor:1,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Se halla debidamente marcado el punto de reunión en caso de emergencia	"},
    {id:"22P24CRG",seccion_clave:"24CRG",checked:false,valor:1,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"No hay almacenamiento de materiales o mobiliario en áreas prohibidas.	"},
    {id:"23P24CRG",seccion_clave:"24CRG",checked:false,valor:2,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Existen detectores de humo supervisados a tablero principal en todos los vestíbulos de ascensores de huéspedes y de servicio.	"},
    {id:"24P24CRG",seccion_clave:"24CRG",checked:false,valor:2,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"No hay  rociadores afectados por pintura que no sea la original o en malas condiciones en pasillos  áreas públicas, salones y centros de consumo. Si los rociadores cuentan con tapa protectora en áreas públicas, centro de consumo, etc., estas se hallan únicamente con su acabado original y no están afectadas con cualquier otra pintura o acabado.	"},
    {id:"25P24CRG",seccion_clave:"24CRG",checked:false,valor:2,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Todas las escaleras de emergencia se hallan con puertas cerradas y en buenas condiciones, funcionan bien los cierra puertas (penichets) y están normadas 60 minutos de resistencia al fuego.	"},
    {id:"26P24CRG",seccion_clave:"24CRG",checked:false,valor:2,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Existen detectores de humo supervisados por tablero principal de sistema contra incendio en la casa de máquinas de los ascensores.	"},
    {id:"27P24CRG",seccion_clave:"24CRG",checked:false,valor:2,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Existen dispositivos alarmados en el tablero del sistema contra incendio y no se pueden restablecer	"},
    {id:"28P24CRG",seccion_clave:"24CRG",checked:false,valor:2,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"El tablero principal del sistema contra incendio en área de lobby se halla funcionando.	"},
    {id:"29P24CRG",seccion_clave:"24CRG",checked:false,valor:2,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"El control de funcionamiento de las bombas en el tablero de control de la bomba contra incendio (tipo gráfica, tipo registro en papel rollo o tipo digital), se halla trabajando normalmente.	"},
    {id:"30P24CRG",seccion_clave:"24CRG",checked:false,valor:2,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Las bombas diésel o eléctrica y jockey se hallan en perfectas condiciones de funcionamiento , sin fallas ni ruidos anormales. Se hallan bien pintados y con apropiadas condiciones de ventilación	"},
    {id:"31P24CRG",seccion_clave:"24CRG",checked:false,valor:2,marca:0,respuesta:false,hora:"",fecha:"",capex:false,notas:[],pregunta:"Los tableros de las bombas contra incendio se encuentran limpios, y  funcionando sin fallas ni alarmas visibles	"},
  ];
}
