import { Component, OnInit, ElementRef, ViewChild, HostListener, AfterViewInit } from '@angular/core';
import { HoteldataService } from '../services/hoteldata.service';
import { TestdataService, questions, test, storedTest, foto } from '../services/testdata.service';
import { Sections, Hotels } from '../planning/planning';
import * as moment from 'moment';
import 'moment/locale/es';
import daycaca from 'daycaca';
import { Subject, Observable, fromEvent, of } from 'rxjs';
import { HotelsService } from '../rest/hotels.service';
import { EvalService, loadPhoto } from '../rest/eval.service';
import { Router } from '@angular/router';
import { NotificationsService } from 'angular2-notifications';
import { AuthService } from '../rest/auth/auth.service';
import { pluck, tap, merge, map } from 'rxjs/operators';
import { data_test } from '../services/current-config';
import { environment } from 'src/environments/environment';

export interface IWindow extends Window {
  webkitSpeechRecognition: any;
}

export interface key_sel{
  nombre : string,
  clave : string,
  puntos : number,
}

export interface file{
  lastModified: number,
  name: string,
  size: number,
  type: string,
  webkitRelativePath: string​,
}

const {webkitSpeechRecognition} : IWindow = <IWindow>window;

@Component({
  selector: 'app-test',
  templateUrl: './test.component.html'
})
export class TestComponent implements OnInit {
  //static
  questionCircle:number = 36;
  reacColor:string = "#c5d9ad";
  currentIndex=1;
  MenuLinks: Array<string> = ["/planning","/planning/sort",'/planning/arrange','/'];

  //Booleans||style
  navReached:boolean = false;
  MenuOn:boolean = false;
  isOpenReactions:boolean = true;
  overflowed:boolean = false;
  overflowedNScrolled:boolean = false;
  comment_window:boolean = false;
  imgVisor:boolean = false;
  questions_desplacement:number = this.questionCircle;
  microphone:boolean;
  _grabando:boolean=false;
  saveCount:boolean=false;
  visibility:boolean;
  summary:boolean=false;
  showComponent:boolean = false;
  loading:boolean=false;

  $online:Observable<object>;
  $offline:Observable<object>;

  ConnectionStatus:boolean;

  //Data
  reacPos:number=0;
  hotel_info:Hotels;
  limit_score:number=0;
  playing_score:number=0;
  visita:number=1;
  secciones:Array<key_sel> = [];
  preguntas: Array<questions>;
  brenchSection:Array<questions> = [];

  laggedImages:Array<any> = [];


  currentPreguntaData : questions = {
    id:"001",
    seccion_clave:"Loading...",
    checked:false,
    pregunta:"Loading...",
    marca:0,
    valor:null,
    respuesta:false,
    hora:"000",
    fecha:"00",
    capex:false,
    notas:[],
    fotos:[]
  };

  get hotelScore(){
    this._hoteldata.restore();
    return this._hoteldata.currentConfig.data.playing_score;
  }

  set hotelScore(val:number){
    this._hoteldata.currentConfig.playing_score = val;
  }

  //Value unset
  currentSectionData:Sections;
  recognition;
  userDictation;
  waitForIt:any;

  constructor(
    private _router : Router,
    public _hoteldata : HoteldataService,
    public _testdata : TestdataService,
    private _hotelService:HotelsService,
    public _notifications: NotificationsService,
    public _authService : AuthService,
    private _evalService : EvalService,
  ) {

    this.$online = fromEvent(window, 'online');
    this.$offline = fromEvent(window, 'offline');

    this.ConnectionStatus = navigator.onLine;

    this.$online.pipe(
      pluck("type"),
    ).subscribe(
      res=>{
        this.ConnectionStatus= res == "online",//returns true
        console.log("this.ConnectionStatus",this.ConnectionStatus);
        this.loadLaggedImages();
        setTimeout(() => {
          
          if(this.ConnectionStatus)this.syncData(true);
        }, 500);
      },
      err=>{
        if(this.ConnectionStatus) console.log("error",err);
      }
    );

    this.$offline.pipe(
      pluck("type"),
    ).subscribe(
      res=>{
        this.ConnectionStatus= res == "online";//returns false
        console.log("this.ConnectionStatus",this.ConnectionStatus);
      },
      err=>{
        if(this.ConnectionStatus) console.log("error",err);console.log("err",err)
      }
    );


    this.visibility = document.hidden;
    if (!('webkitSpeechRecognition' in window)) this.microphone = false;
    else this.initRecognition();

    this._evalService.evalDetail(this._hoteldata.currentConfig.data.hotel.clave,this._hoteldata.currentConfig.data.test.id)
    .pipe(
      pluck("preguntas")
    ).subscribe(
      (res:any)=>{
        if (res){
          let preguntas = JSON.parse(res);
          //console.log("test res=>preguntas",preguntas);
          if(res.length!==0 || res !== []){
            this._hoteldata.currentConfig.preguntas = preguntas;
          }
        }
      }
    )

    this._hoteldata.restore();
    this.getHotelData(this._hoteldata.currentConfig.data.hotel.clave);

    if(!this._hoteldata.currentConfig.data.hotel.clave) this._router.navigate(['/planning']);

    this.initTest(true);
  }

  goToMenu(link:number){
    if(!this.ConnectionStatus){
      if(!confirm("No está conectado a internet y podría perder datos, ¿desea continuar?"))return;
    }
    this.syncData(false);
    this._router.navigate([`${this.MenuLinks[link]}`]);
  }

  final_transcript:string = "";
  dictationChange:Subject<any> = new Subject<any>();

  initRecognition(){
    this.recognition = new webkitSpeechRecognition();
    this.microphone = true;
    this.recognition.continuous = true;
    this.recognition.interimResults = true;
    this.recognition.lang = 'es-MX';

    this.recognition.onstart = ()=>{
      this.final_transcript = "";
      this.statusGrabacion="started";
    }

    this.recognition.onend = ()=>{
      this.statusGrabacion="ended";
    }

    this.recognition.onerror = event =>{
      console.log(event);
    }

    this.recognition.onresult = event =>{
      let interim_transcript:string = "";
      let final:boolean = false;

      for (var i = event.resultIndex; i < event.results.length; i++) {
        if (event.results[i].isFinal) {
          console.log("isFinal x1?",event.results[i].isFinal);
          final=true;
          this.final_transcript = event.results[i][0].transcript;
        } else {
          console.log("isFinal x2?",event.results[i].isFinal);

          final=false;
          interim_transcript += event.results[i][0].transcript;
        }
      }
      this.final_transcript = this.capitalize(this.final_transcript);

      this.nativeCommentInterface.nativeElement.value =final?this.final_transcript:interim_transcript;
      this.textoGrabado = final?this.final_transcript:interim_transcript;

      console.log("final_transcript: ",this.final_transcript);
    }
  }

  grabar(){
    try{
      this.recognition.start();
      this._grabando = true;
    } catch (msg) {
      console.log("Recognition error:",msg.message);
    }
  }

  stop(){
    this.recognition.stop();
    this._grabando = false;
  }

  capitalize(string:string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  @ViewChild('commentInterface') nativeCommentInterface : ElementRef;
  //Detect overflowed text
  @ViewChild('literalQuestionBox') nativeLiteralquestionbox : ElementRef;

  logout(){

    if(!this.ConnectionStatus){
      console.log("this.ConnectionStatus",this.ConnectionStatus);
      if(confirm("No está conectado a internet y podría perder datos, ¿desea continuar?")) {
        this._authService.logout();
      }
    }else{
      this.syncData(false)
      this._authService.logout();
    }

  }

  async syncData(showAdvice?:Boolean){ // Añadir total score
    if(this.saveCount) { window.clearTimeout(this.waitForIt); }
    this.saveCount=false;


    this._hoteldata.restore();
    //this._testdata.testRestore();
    this.totalScore();
    let data = this._hoteldata.currentConfig.data;

    if(!data.user||!data.hotel.clave||data.test.id==undefined){
      console.log("Retorned");
      return;
    }

    let _syncData = {
      //cuestionario_clave: this._hoteldata.currentConfig.data.test.clave,
      //hotel_clave: this._hoteldata.currentConfig.data.hotel.clave,
      //visita: this._hoteldata.currentConfig.data.test.visita||0,
      secciones: JSON.stringify(this._hoteldata.currentConfig.data.secciones) || "''",
      preguntas: JSON.stringify(this._hoteldata.currentConfig.data.preguntas) || "''",
      //status: this._hoteldata.currentConfig.data.test.status,
      puntuacion_final: `${this._hoteldata.currentConfig.data.playing_score} - ${this._hoteldata.currentConfig.data.limit_score}`,
      //user_id: this._hoteldata.currentConfig.data.user.user_id ,//actualizar user id
    }
    //console.log("_syncData",_syncData);

    const ls_test:data_test = {
      id:data.test.id,
      clave:this._hoteldata.currentConfig.data.test.clave,
      saved:true,
      visita:this._hoteldata.currentConfig.data.test.visita,
      status:this._hoteldata.currentConfig.data.test.status,
    }

    this._evalService.updateEval(_syncData,data.test.id)
    .subscribe(
      res=>{
        let server_data:any = Object.keys(res)[0];

        console.log("res",server_data)
        console.log("raw res",res);

        if(server_data=="status"){

          this._notifications.warn(
            "Advertencia",//title
            "La sesión ha caducado",//content
            this._authService.settings //override
          );

        }else{

          if(showAdvice){
            this._notifications.success(
              "Guardado",//title
              "Los datos se sincronizaron",//content
              this._authService.settings //override
            );
            this._hoteldata.currentConfig.test.saved = true;
          }
          this._hoteldata.currentConfig.test.saved=true;
          this._hoteldata.changer("test",ls_test);

        }


      },
      err=>{
        console.log("error",err);
        this._notifications.error(
          "Error al guardar",//title
          "Imposible sincronizar sin internet o sesión",//content
          this._authService.settings //override
        );
      }
    )

    this.MenuOn = false;
  }

  questionScrolled(){
    setTimeout(() => {
      let lbox = this.nativeLiteralquestionbox.nativeElement;
      this.overflowedNScrolled=lbox.scrollTop == 0;
    }, 300);
  }

  getHotelData(clave?:string){
    let Hotel:Hotels;
    this._hotelService.hotelDetails(clave)
    .subscribe( details => {

      if(!details[0]){
        //this._authService.logout();
        this._notifications.warn(
          "Ojo",//title
          "No se recuperaron datos, intenta salir y entrar de nuevo",//content
          this._authService.settings //override
        );
      }else{
        Hotel = {
          id:details[0].id,
          name:details[0].hotel,
          clave:details[0].clave,
          edad:0,
          limit_score: this._hoteldata.currentConfig.data.limit_score,
          playing_score: this._hoteldata.currentConfig.data.playing_score,
          gerente:details[0].gerente_general,
          habitaciones:details[0].habitaciones,
          logo:`${environment.Protocol}://${environment.Host}/assets/images/logos/${details[0].logo}`,
        }
        this._hoteldata.currentConfig.hotel = Hotel;
        this.hotel_info = Hotel;
        this.make_totalScore();
      }

    });
  }

  //Manage input section info
  @ViewChild('selSection') nativeSelSection: ElementRef;
  _currentSelection:string;

  get currentSection(){
    this._currentSelection = this.nativeSelSection.nativeElement.value;
    return this._currentSelection;
  }

  set currentSection(val:string){
    this._currentSelection = val;
    this.nativeSelSection.nativeElement.value = this._currentSelection;
  }

  initTest(loadSections?:boolean){
    this._hoteldata.restore();

    // this._testdata.testRestore();
    this.totalScore();
    this.limit_score = this._hoteldata.currentConfig.data.limit_score;
    this.playing_score = this._hoteldata.currentConfig.data.playing_score;
    this.visita = this._hoteldata.currentConfig.data.test.visita;

    if(loadSections){
      // Genera llaves y nombres desde secciones en currentConfig.data
      this._hoteldata.currentConfig.data.secciones.forEach(clave => {
        let name:Sections = this._hoteldata.Secciones.find(el=>{ return el.id == clave });
        let obj = {nombre: name.name, clave: name.id, puntos:name.total_score};
        this.secciones.push(obj);
      });
    }

    // Filtrar preguntas existentes
    let filteredQuestions = []
    this._testdata.Preguntas.forEach(_tpregunta=>{
      let filtered = this.secciones.filter( seccion => seccion.clave == _tpregunta.seccion_clave );
      let existing = this._hoteldata.currentConfig.data.preguntas.filter( exist =>  exist[0] == _tpregunta.id);
      if(existing && existing.length!==0) {
        _tpregunta.checked= existing[0][2] !== "undefined";
      }
      if(filtered && filtered.length!==0) filteredQuestions.push(_tpregunta);
    });
    this.preguntas  = filteredQuestions;
  }

  make_totalScore(){
    let total:number=0;
    this.secciones.forEach(seccion=>{
      total = total + seccion.puntos;
    });
    this.hotel_info.limit_score = total;
  }

  // Desencadenar eventos al elegir seccion en input
  setSection(disSection?:string){
    this.currentSection = disSection || this.nativeSelSection.nativeElement.value;
    if(disSection) {
      this.nativeSelSection.nativeElement.value = disSection;
      this.initTest();
    }

    let first = this.preguntas.find(question=>{
      return question.seccion_clave == this.currentSection;
    });
    this.matchKeySection(false);
    this.makePregunta(first);
    this.questions_desplacement = this.questionCircle;
  }

  matchKeySection(OnInit:boolean){ // Ubicar Datos de sección actual
    let llave:string = this.currentSection; //Viene de input

    if(OnInit) llave = this.secciones[0].clave;

    this._hoteldata.Secciones.forEach( section => {
      if ( section.id == llave ) this.currentSectionData = section;
    });
  }

  //Renderear pregunta y esparcir data
  makePregunta(selectedPregunta:questions,index?:number){
    let visited:boolean = selectedPregunta.checked;
    // console.log("visited",visited);

    if(!this.isOpenReactions) this.toggleReactions();

    this.currentPreguntaData.id = selectedPregunta.id;
    this.currentPreguntaData.pregunta = selectedPregunta.pregunta;
    this.currentPreguntaData.checked = visited;
    this.currentPreguntaData.marca = selectedPregunta.marca;
    this.currentPreguntaData.capex = selectedPregunta.capex;
    this.currentPreguntaData.valor = selectedPregunta.valor;
    this.currentPreguntaData.notas = selectedPregunta.notas;
    if(selectedPregunta.fotos) this.currentPreguntaData.fotos = selectedPregunta.fotos;
    else this.currentPreguntaData.fotos = [];

    let savedQuestion = this._hoteldata.currentConfig.data.preguntas.find( pregunta => {return pregunta[0] == selectedPregunta.id} );
    if (savedQuestion !== undefined){
      //Considerando un solo cuestionario en LS carga id de pregunta existente

      console.log("savedQuestion",savedQuestion);

      this.currentPreguntaData.marca = savedQuestion[3];
      this.currentPreguntaData.respuesta = savedQuestion[2];
      this.currentPreguntaData.capex = savedQuestion[6];
      this.currentPreguntaData.notas = savedQuestion[7];
      this.currentPreguntaData.fotos = savedQuestion[8];
    }

    //reset or loadin reaction
    this.reaction(this.currentPreguntaData.marca);

    let editedIndex= this.preguntas.indexOf(selectedPregunta);
    if (!index) this.currentIndex = Number(editedIndex+1);
    else this.currentIndex = Number(index) + 1; //Index Sent

    this.deslizarCentro(selectedPregunta.id);

    // Efecto al haber texto desbordado
    setTimeout(() => {
      this.overflowed = this.nativeLiteralquestionbox.nativeElement.scrollHeight > 96;
    }, 350);
    this.questionScrolled();

    this.autosave();
  }

  @ViewChild("questionType") nativeQuestion : ElementRef;

  get Question(){
    return this.clearType(this.nativeQuestion.nativeElement.value);
  }

  set Question(value){
    this.nativeQuestion.nativeElement.value = value;
  }

  filterPreguntas:Array<any>=[];
  showFilteredQuestions:boolean = false;
  showQuestionSearch:boolean = false;

  toogleQuestionSearch(){
    this.showQuestionSearch = !this.showQuestionSearch;
    if(!this.showQuestionSearch) return;
    // Jarvis.say("En esta ventana podrás buscar preguntas por palabras clave",{ lang:"es-ES" });
    setTimeout(() => {
      this.nativeQuestion.nativeElement.focus();
    }, 200);
    this.choosenFilter="";
  }

  textoGrabado:string="";
  statusGrabacion:string ="";

  clearType(texto:string){
    texto = texto.replace(/á/gi,"a");
    texto = texto.replace(/é/gi,"e");
    texto = texto.replace(/í/gi,"i");
    texto = texto.replace(/ó/gi,"o");
    texto = texto.replace(/ú/gi,"u");
    texto = texto.toLowerCase();
    return texto;
  }

  choosenFilter:string = "";

  filterBy(selection:string){
    // this.choosenFilter = selection;
    this.choosenFilter = this.choosenFilter == selection && selection !== "" ? "" : selection;
    if(this.choosenFilter!==""){
      this.filterPreguntas = [];
      this._testdata.Preguntas.forEach( (pregunta:questions) => {
        this.preguntaSearcher(pregunta);
      });
      this.showFilteredQuestions = true;
    }
  }

  key_preguntas(){
    // this._testdata.testChanges();
    this.filterPreguntas = [];
    if( this.Question.length < 2) this.showFilteredQuestions = false;
    else this.showFilteredQuestions = true;

    this._testdata.Preguntas.forEach( (pregunta:questions) => {
      let question:string = this.clearType(pregunta.pregunta);
      if( question.indexOf(this.Question) !== -1 && this._hoteldata.currentConfig.data.secciones.indexOf(pregunta.seccion_clave) !== -1 ){
        this.preguntaSearcher(pregunta);
      }
    });
  }

  preguntaSearcher(pregunta){
    this._hoteldata.restore()
    // let savedTest:storedTest = this._testdata.savedTest.find(stest=>{return stest.id == this._hoteldata.currentConfig.data.test.id});
    let existentP;
    existentP = this._hoteldata.currentConfig.preguntas.find( ep=>{ return ep[0] == pregunta.id });
    if(existentP!==undefined) pregunta.respuesta = existentP[2];
    else pregunta.respuesta = "--"

    let filterResponse: boolean | string;
    switch (this.choosenFilter) {
      case 'close':
        filterResponse = false;
      break;
      case 'checked':
        filterResponse = true;
      break;
      case 'forbidden':
        filterResponse = "discard";
      break;
      default:
      filterResponse = "sinFiltro"
      break;
    }

    if(pregunta.respuesta == filterResponse)  this.filterPreguntas.push(pregunta);
    if(filterResponse == "sinFiltro") this.filterPreguntas.push(pregunta);
  }

  flyTo(preguntaClave:string){
    this._hoteldata.currentConfig.lastVisit = preguntaClave;
    this.loadLastSection();
    this.showQuestionSearch = false;
    this.showFilteredQuestions = false;
  }

  translateClave(clave){
    let secData = this._hoteldata.Secciones.find( seccion => { return seccion.id == clave });
    if(secData) return secData.name;
  }

  totalScore(){
    let suma:number = 0;
    this._hoteldata.currentConfig.data.preguntas.forEach(pregunta => {
      let fullPregunta = this._testdata.Preguntas.find(fp=>{return fp.id == pregunta[0]});
      if(pregunta[2] && pregunta[2]!=="discard") suma += fullPregunta.valor;
    });
    this._hoteldata.currentConfig.playing_score = suma;
    let wchTest = this._testdata.Cuestionarios.find( tst => { return tst.id == this._hoteldata.currentConfig.data.test.id });
    let indexTest = this._testdata.Cuestionarios.indexOf(wchTest);

    if(indexTest!==-1) this._testdata.Cuestionarios[indexTest].playing_score = suma;
    // this._testdata.testChanges();
  }

  newComment(){
    this.editingComment=false;
    let textarea:string = this.capitalize(this.nativeCommentInterface.nativeElement.value);
    if(textarea.length!==0)this.currentPreguntaData.notas.push(textarea);

    this.toggleCommentWindow();
    this._hoteldata.currentConfig.test.saved=false;
    this.storeWithoutAnswering();
  }

  deleteComment(comment:string){
    let index = this.currentPreguntaData.notas.indexOf(comment);
    this.currentPreguntaData.notas.splice(index,1);
    this._hoteldata.currentConfig.test.saved=false;
    this.storeWithoutAnswering();
  }

  deslizarCentro(PreguntaID){
    //Desplazar contenedor de preguntas(.spacer)
    let currentSectionArray:Array<questions> = [];
    this.preguntas.forEach(edSec=>{
      if(edSec.seccion_clave == this.currentSection) currentSectionArray.push(edSec.id);
    });
    let posQuest = currentSectionArray.indexOf(PreguntaID);

    if(!posQuest) posQuest = 0;
    this.questions_desplacement = this.questionCircle * (posQuest+1);
  }

  deleteAllQuestions(){
    this._hoteldata.currentConfig.preguntas = [];
    this._hoteldata.restore();
  }

  addCapex(){
    this.currentPreguntaData.capex = !this.currentPreguntaData.capex;
    this.storeWithoutAnswering();
  }

  @ViewChild("imgMaster") nativeImgMaster : ElementRef;
  imgSelection:string="unselected";

  toggleShowImg(imgName:string){
    this.imgVisor = !this.imgVisor;
    if(this.imgVisor && this.imgSelection == "unselected") {
      this.imgSelection = imgName;
      window.scrollTo(0,0)
    }
    else this.imgSelection = "unselected";
  }

  editingComment:boolean=false;
  toggleCommentWindow(comment?:string){
    this.comment_window = !this.comment_window;

    if(comment){
      this.editingComment=true;
      this.deleteComment(comment);
      this.nativeCommentInterface.nativeElement.value = comment;
    }else{
      if(!this.comment_window && this.microphone) this.stop();
      setTimeout(() => {
        this.nativeCommentInterface.nativeElement.value="";
      }, 380);
    }

  }

  toggleReactions(){
    let currentPos:number = this.reacPos;
    if(this.isOpenReactions) this.reacPos = 0;
    else this.reacPos = currentPos;
    this.isOpenReactions = !this.isOpenReactions;
  }

  reaction(selection:string|number,Save?:boolean){
    switch (selection) {
      case 'cancel':
      case 0:
      this.reacPos = 0;
      this.currentPreguntaData.marca=0;
      this.reacColor = "#818181";
      break;
      // case 'clock':
      // case 1:
      // this.reacPos = -49;
      // this.currentPreguntaData.marca=1;
      // this.reacColor = "#c5d9ad";
      // break;
      case 'warn':
      case 2:
      this.reacPos = -49;
      this.currentPreguntaData.marca=2;
      this.reacColor = "#dea945";
      break;
      // case 'seen':
      // case 3:
      // this.reacPos = -160;
      // this.currentPreguntaData.marca=3;
      // this.reacColor = "#e06d2a";
      // break;
      case 'deathly':
      case 4:
      this.reacPos = -106;
      this.currentPreguntaData.marca=4;
      this.reacColor = "#2a1212";
      break;
      default:
      this.reacPos = 0;
      this.currentPreguntaData.marca=0;
      this.reacColor = "#818181";
      break;
    }
    this._hoteldata.currentConfig.test.saved=false;
    if(Save)this.storeWithoutAnswering();
  }

  storeWithoutAnswering(){
    let doesItExist = this._hoteldata.currentConfig.data.preguntas.find(x=>x[0]==this.currentPreguntaData.id);

    if(doesItExist==undefined){
      let unaswered:Array<any> = [];

      unaswered.push(this.currentPreguntaData.id);
      unaswered.push(this._hoteldata.currentConfig.data.test.id);
      unaswered.push("undefined");
      unaswered.push(this.currentPreguntaData.marca);
      unaswered.push(moment().format('DD[/]MM[/]YYYY'));
      unaswered.push(moment().format('h:mm:ss a'));
      unaswered.push(this.currentPreguntaData.capex);
      unaswered.push(this.currentPreguntaData.notas);
      unaswered.push(this.currentPreguntaData.fotos);

      this._hoteldata.currentConfig.data.preguntas.push(unaswered)

      this._hoteldata.forceUpload.next(this._hoteldata.currentConfig.data);
      this.initTest();
    }else{
      if(this.currentPreguntaData.respuesta=="discard"){
        this.answerTheQuestion(false,"discard",true);
      }
      this.answerTheQuestion(this.currentPreguntaData.respuesta,"",true);
    }

    if(this.ConnectionStatus)this.syncData(false);
  }

  abstracAnswer(calification:boolean,qClave:string){
    let configQ:Array<any> = this._hoteldata.currentConfig.data.preguntas;
    let disQuestion = configQ.find(x=>{ return x[0] == qClave });
    let Qindex:number = configQ.indexOf(disQuestion);

    if(Qindex!== -1) {
      this._hoteldata.currentConfig.preguntas[Qindex][2] = calification;
      // console.log(`Pregunta ${qClave} changed to ${calification}`,this._hoteldata.currentConfig.preguntas[Qindex]);
      this.totalScore();
      this._hoteldata.forceUpload.next(this._hoteldata.currentConfig.data);
      if(this.ConnectionStatus)this.syncData(false);
    }

  }

  answerTheQuestion(sel?:boolean|any,discard?:string,justSave?:boolean){

    if(discard=="discard") {
      //console.log("descartar"); // remove from score
      this.hotel_info.limit_score = Number(this.hotel_info.limit_score) - Number(this.currentPreguntaData.valor);
      this._hoteldata.currentConfig.limit_score = this.hotel_info.limit_score;
    }

    let actualQuestion:Array<any> = []

    //Orden preguntas...
    // [PreguntaId:string,TestId:string,Respuesta:boolean|string,Marca:number,Fecha:any,Hora:any,Capex:boolean,notas[],fotos[],ponderación:number]

    actualQuestion.push(this.currentPreguntaData.id);
    actualQuestion.push(this._hoteldata.currentConfig.data.test.id);
    //actualQuestion.push(!discard && sel !==undefined ? sel : !sel && !discard ? undefined : discard);
    actualQuestion.push(discard == "discard"? discard : sel); //prueba
    actualQuestion.push(this.currentPreguntaData.marca);
    actualQuestion.push(moment().format('DD[/]MM[/]YYYY'));
    actualQuestion.push(moment().format('h:mm:ss a'));
    actualQuestion.push(this.currentPreguntaData.capex);
    actualQuestion.push(this.currentPreguntaData.notas);
    actualQuestion.push(this.currentPreguntaData.fotos);
    let ponderacion = this._testdata.Preguntas.find( x=>{ return this.currentPreguntaData.id == x.id});
    actualQuestion.push(ponderacion.valor);

    console.log("actualQuestion",actualQuestion);
    let configQ:Array<any> = this._hoteldata.currentConfig.data.preguntas;
    let c_question = configQ.find(existing=>{ return existing[0] == this.currentPreguntaData.id });
    //console.log("c_question",c_question);
    if(c_question !== undefined){ //Si ya se visitó se actualiza
      let index:number = configQ.indexOf(c_question);

      this._hoteldata.currentConfig.preguntas[index] = actualQuestion;
    }else this._hoteldata.currentConfig.data.preguntas.push(actualQuestion);

    this.totalScore();
    this._hoteldata.currentConfig.test.saved=false;

    if(justSave) return;

    this.initTest();
    this.nextStep();

    if(this.saveCount) {
      console.log("desviado");
      return
    };

    this.saveCount = true;
    this.waitForIt = setTimeout(()=>{
      // console.log("Lets save some data..");
      if(this.ConnectionStatus)this.syncData();
    },1000);

    

  }

  nextStep(){
    // Actual settings
    let preguntaActual:questions = this.currentPreguntaData.id;

    // Ubicar siguiente pregunta
    let pregunta=this.preguntas.find(pregunta => { return pregunta.id == preguntaActual});
    let index = this.preguntas.indexOf(pregunta);
    let nextPregunta= this.preguntas[(index)+1];

    if(!nextPregunta || nextPregunta.seccion_clave!==pregunta.seccion_clave ){// Detectar si es la última pregunta de la sección => cambiar a siguiente sección
      //Obtener nueva seccion
      let secIndex:number = this._hoteldata.currentConfig.data.secciones.indexOf(pregunta.seccion_clave);
      let nextSection:string =this._hoteldata.currentConfig.data.secciones[(secIndex)+1];

      if (nextSection){// Detectar si es la última preginta del test => send to hotelSearch o mensaje de terminado
        this.currentSection = nextSection;
        this.setSection();
      }else{
        console.log("Final de evaluación");
      }
    }else{
      // Avanzar a la siguiente pregunta
      this.makePregunta(nextPregunta);
    }

  }

  toogleMenu(){
    this.MenuOn = !this.MenuOn;
  }

  navScroll($event){
    if ($event.target.scrollLeft <= 10) this.navReached = true;
    else this.navReached = false;
  };

  autosave(){ // Recordar última visita
    this._hoteldata.currentConfig.lastVisit=this.currentPreguntaData.id;
    // this._testdata.strdData();
  }

  loadLastSection(){
    let lastQuestion:string = this._hoteldata.currentConfig.data.lastVisit;
    let lastSection:string = lastQuestion.slice(-5);
    let formattedQuestion = this.preguntas.find( el => { return el.id == lastQuestion });

    if(lastQuestion!==""){
      setTimeout(() => {
        this.currentSection = lastSection;
        this.matchKeySection(false);
        this.makePregunta(formattedQuestion);
        this.deslizarCentro(lastQuestion)
      },300);
    }else{
      this.matchKeySection(true);
    }
  }

  makeFromReport(pregunta,seccion){
    console.log("pregunta",pregunta);
    console.log("seccion",seccion);

    this.currentSection = seccion;
    this.setSection();
    this.makePregunta(pregunta);
  }

  ///////////////////// Lector de imágenes /////////////////////

  @ViewChild("fotosInput") nativeFotosInput:ElementRef;
  _fileList:Array<file>;

  leerImagenes($event){
    this._fileList = $event.target.files;
    let errors = "";

    if(!this._fileList) errors+= "Archivo no soportado; admitidos: JPG y PNG.";
    if(this._fileList && this._fileList[0]){

      for (let i = 0; i < this._fileList.length; i++) {
        let _file = this._fileList[i];
        if ( (/\.(jpeg|jpg|png)$/i).test(_file.name) ) {
          if(Math.round(_file.size/1024) < 7500){
              if(this.ConnectionStatus) this.loading=true;
              this.readFile( _file , _file.name);
          }else errors += "El tamaño de la imagen debe ser menor a 4.5MB";
        } else errors += _file.name +", extensión de archivo no soportada\n";
      }
    }
    if (errors){
      this._authService._notifications.warn(
        "Precaución",
        errors,
        this._authService.settings
      )
      console.log(errors);
    }
  }

  imgEntry:foto;

  readFile(file,filename:string){
    let reader = new FileReader();
    this.imgEntry=undefined;

    let imageObj = window.URL.createObjectURL(file);
    
    reader.addEventListener("load",a=>{      
        daycaca.resize(imageObj, !this.ConnectionStatus? 0.2 : 0.25, data=>{
          daycaca.compress( data, !this.ConnectionStatus? 29 : 35, subdata=>{
            if(this.currentPreguntaData.fotos==null) this.currentPreguntaData.fotos = [];
              this.imgEntry = {name:filename,url:subdata,id:null};
              this.loadImage(filename,subdata);
              this.currentPreguntaData.fotos.push(this.imgEntry);
          });
        });
    });
    
    reader.readAsDataURL(file);
    this._hoteldata.currentConfig.test.saved=false;

  }

  loadImage(name:string,data:string){
    const imgData:loadPhoto = {
      hotel_clave:this._hoteldata.currentConfig.data.hotel.clave.toString(),
      cuestionario_clave:this._hoteldata.currentConfig.data.test.id.toString(),
      pregunta_clave:this._hoteldata.currentConfig.data.lastVisit.toString(),
      name:name,
      base64:data
    }

    //console.log("imgData",imgData);

    if(!this.ConnectionStatus){
      this.storeWithoutAnswering();
      console.log("test offline: ",this.ConnectionStatus);
      this.laggedImages.push(imgData);
      return;
    }

    this._evalService.uploadPhoto(imgData)
    .pipe(
      pluck("data_photo"),
      tap((x:any)=>{
        this.changeBase64ToURL(x.url,name,x.id);
        this.imgEntry.id=x.id;
      })
    )
    .subscribe(
      res=>{
        console.log("test online: ",this.ConnectionStatus);
        this.storeWithoutAnswering();
      },
      err=>{
        console.log("error",err);
      },
      ()=>{
        console.log("image loaded");
        this.loading=false;
      }
    )
  }

  loadLaggedImages(){
    console.log("this.laggedImages",this.laggedImages);
    if(this.laggedImages.length > 0){
      this.laggedImages.forEach((elimg:loadPhoto) => {
        this.loadImage(elimg.name,elimg.base64);
        console.log(`${elimg.name} lagged image`);
        let subindex = this.laggedImages.indexOf(elimg);
        this.laggedImages.splice(subindex,1);
      });
    }else console.log("Nothing lagged to load");
  }

  changeBase64ToURL(newURL:string,filename:string,id:number){
    const nwImg = {
      name:filename,
      url:`${environment.Protocol}://${environment.Host}/${newURL}`,
      id:id
    }

    let oldImg = this.currentPreguntaData.fotos.find((x:any)=>{return x.name == filename})
    let index = this.currentPreguntaData.fotos.indexOf(oldImg);

    this.currentPreguntaData.fotos[index] = nwImg;
  }

  cerrarImg(imgName:string,id:number){
    console.log("id",id);

    if(!this.ConnectionStatus){
      this.removeImagesLS(imgName)
      return;
    }

    this._evalService.removePhoto(id)
    .subscribe(
      res=>console.log(Object.keys(res)[0]),
      err=>{
        if(this.ConnectionStatus)console.log('Hubo un error: ',err)
      },
      ()=> this.removeImagesLS(imgName)
    )
  }

  removeImagesLS(imgName:string){

    if(this.laggedImages.length > 0){
      let subindex = this.laggedImages.indexOf(imgName);
      this.laggedImages.splice(subindex,1);
    }

    let ind = this.currentPreguntaData.fotos.find(pdata => {
      return pdata.name == imgName;
    });
    let index = this.currentPreguntaData.fotos.indexOf(ind);
    this.currentPreguntaData.fotos.splice(index,1);
    this._hoteldata.currentConfig.test.saved=false;

    this.answerTheQuestion(this.currentPreguntaData.respuesta,"",true);
  }

  toggleSummary(value?:boolean){
    if(value||!this.summary){
      this.summary=value||!this.summary;
      this.MenuOn = false;
      setTimeout(() => {
        this.showComponent= true;
      }, 500);
    }else{
      this.showComponent= false;
      setTimeout(() => {
        this.summary=value||!this.summary;
        this.MenuOn = false;
      }, 500);
    }
  }

  ngOnInit() {
    this.preguntas.forEach(pregunta => { // juntar preguntas con misma seccion ID
      if (pregunta.seccion_clave == this.secciones[0].clave){
        this.brenchSection.push(pregunta);
      }
    });

    //Renderear pregunta
    if(this._hoteldata.currentConfig.data.lastVisit!=="") {
      this.matchKeySection(true);
      this.loadLastSection();
    }else {
      this.makePregunta(this.brenchSection[0],0)
      this.matchKeySection(true);
      this.questions_desplacement = this.questionCircle;
    };

    fromEvent(document,"visibilitychange")//App/ventana unactive
    .subscribe(
      res=>{
        this._hoteldata.restore();
        const saved = this._hoteldata.currentConfig.data.test.saved;
        this.visibility=document.hidden;
        if(this.visibility && !saved){
          this.syncData();
          this._notifications.info(
            "Nota",//title
            "Guardado automático por inactividad",//content
            this._authService.settings //override
          );
        }
      }
    )
  }

}
