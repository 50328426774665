// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

let host = "fihosolutions.mx";
let protocol = "https";

export const environment = {
  Host:host,
  Protocol:protocol,
  quickSearch: `${protocol}://${host}/api/hoteles/busqueda-rapida/index`,
  login: `${protocol}://${host}/api/login`,
  eval: `${protocol}://${host}/api/evaluacion`,
  evals: `${protocol}://${host}/api/evaluaciones`,
  evalResult: `${protocol}://${host}/api/evaluacionScore`,
  destroyTest: `${protocol}://${host}/api/evaluacionDestroy`,
  user: `${protocol}://${host}/api/userDetail`,
  photo: `${protocol}://${host}/api/uploadPhoto`,
  removePhoto : `${protocol}://${host}/api/deletePhoto`,
  reporte: `${protocol}://${host}/api/reporte`,
  production: false
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
