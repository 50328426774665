import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { Hotels, testIndex, HotelEvaluationScore } from '../planning';
import { HoteldataService } from 'src/app/services/hoteldata.service';
import { TestdataService, test } from 'src/app/services/testdata.service';
import { HotelsService, quickHotelSearch, hotelDetails } from 'src/app/rest/hotels.service';
import { AuthService } from 'src/app/rest/auth/auth.service';
import { NotificationsService } from 'angular2-notifications';
import { EvalService, syncData } from 'src/app/rest/eval.service';
import { tap} from 'rxjs/operators';
import { data_test, data_hotel } from 'src/app/services/current-config';
import { environment } from 'src/environments/environment';

export interface retHotel {
  id:number,
  clave: string,
  hotel: string
}

@Component({
  selector: 'app-hotelsearch',
  templateUrl: './hotelsearch.component.html'
})
export class HotelsearchComponent implements OnInit {
  loadHtls:number;
  sHotel:any|Array<testIndex> = [];
  test_new:data_test;
  hotelScores: HotelEvaluationScore;

  // sHotel;
  hoteles:any = this._hoteldataService.Hoteles;
  choosingHotel:quickHotelSearch;

  //triggers
  showScores:boolean = false;
  showSuggests:boolean = false;
  activoNewTest:boolean = true;
  blockTestShortcut:boolean=false;
  loading:boolean=false;

  @ViewChild('searchbar') Searchbar: ElementRef;
  foundedHotels:Array<quickHotelSearch>

  get nombre(){
    return this.Searchbar.nativeElement.value;
  }
  set nombre(value){
    this.Searchbar.nativeElement.value = value;
  }

  get searchbarValue(){
    return this.Searchbar.nativeElement.value;
  }

  set searchbarValye(value){
    this.Searchbar.nativeElement.value = value;
  }

  constructor(
    private _router : Router,
    private _hotelService : HotelsService,
    private _authService : AuthService,
    private _evalService : EvalService,
    public _notifications: NotificationsService,
    public _hoteldataService : HoteldataService,
    public _testdataService : TestdataService,
  ) {
  }

  stop(evt){
    evt.preventDefault()
    return;
  }

  toggleScores(value?:boolean){
    this.showScores = value || !this.showScores;
  }

  indx:number;
  showSelectable:boolean=false;
  key_option(event){

    switch (event.keyCode) {
      case 38://"ArrowUp"
        this.indx = this.indx == 0 ? 0 : this.indx-1 ;
        this.choosingHotel = this.foundedHotels[this.indx];
        this.showSelectable = true;
        return;
      break;
      case 40://"ArrowDown"
        if(this.indx==0 && !this.showSelectable)this.indx = 0;
        else this.indx = this.indx !== this.foundedHotels.length-1 ? this.indx+1 : this.foundedHotels.length-1;
        this.choosingHotel = this.foundedHotels[this.indx];
        this.showSelectable = true;
        return;
      break;
      case 27://"Escape"
        this.showSuggests=false;
        return;
      break;
      case 13://"Enter"
        if(!this.foundedHotels || !this.choosingHotel) return;
        this.showSuggests=false;
        this.showSelectable = false;
        this.clicked(this.choosingHotel.clave)
        return;
      break;
      default:
        this.indx = 0;
      break;
    }

    this.choosingHotel = !this.foundedHotels ? null : this.foundedHotels[this.indx];

    this.foundedHotels = [];
    if( this.nombre.length < 2 ){ this.showSuggests = false; }
    else{ this.showSuggests = true; }

    this.hoteles.forEach(hotel=>{
      if((hotel.hotel.toLowerCase()).indexOf(this.searchbarValue.toLowerCase())!==-1 && this.foundedHotels.length<7) {
        if(
          (
            this._hoteldataService.currentConfig.data.user.user_id==28
            || this._hoteldataService.currentConfig.data.user.user_id==30
          )
           || this._hoteldataService.currentConfig.data.user.user_id==1){
          this.foundedHotels.push(hotel);
        }else{
          if(hotel.clave!=="TEST") this.foundedHotels.push(hotel);
        }
      }
    });

    if(this.foundedHotels.length==0)this.showSuggests = false;
  }

  _selectedHotel:any={
    id:0,
    name:"",
    edad:0,
    clave: "",
    gerente: "",
    habitaciones:0,
    limit_score:0,
    playing_score:0,
    logo:"",
    segmento:""

  }

  clicked(clave:string){
    this.loading=true;
    this._hotelService.hotelDetails(clave)
    .pipe(
      tap(
        x=>{
          const res:any = x[0];
          const dHotel:data_hotel = {
            id:res.id,
            name:res.hotel,
            edad:0,
            clave:res.clave,
            gerente:res.gerente_general,
            habitaciones:res.habitaciones,
            logo:`${environment.Protocol}://${environment.Host}/assets/images/logos/${res.logo}`,
            segmento:res.segmento
          }
          this._hoteldataService.changer("hotel",dHotel);
          // this._hoteldataService.currentConfig.playing_score:,
          // this._hoteldataService.currentConfig.limit_score:,
        }
      )
    ).subscribe(
        details => {
          if(!details[0]){
            // this._authService.logout();
            this._notifications.warn(
              "Ojo",//title
              "No se recuperaron datos, intenta salir y entrar de nuevo",//content
              this._authService.settings //override
            );
            this.loading=false;
          }else{
            this.assignData(details[0]);
            this.lookForTest();
            this.loading=true;
            console.log("details",details)
          }
        },
        err => {
          console.log("error",err);
          this._notifications.warn(
            "Ojo",//title
            "No se pudo conectar con la base de datos",//content
            this._authService.settings //override
          );
          setTimeout(() => {
            this._authService.logout();
          }, 1200);
          this.loading=false;
        },
        ()=>{
          console.log("process end");
        }
      );

  }

  assignData(details:hotelDetails){
    let datos = {
      "id":  details.id,
      "name":  details.hotel,
      "edad":  0,
      "clave":  details.clave,
      "gerente":  details.gerente_general,
      "habitaciones":  details.habitaciones,
      "segmento" : details.segmento,
      "logo": `https://fihosolutions.mx/assets/images/logos/${details.logo}`,
      "final_score":  0,
    }
    this._selectedHotel = datos;
    this.showSuggests = false;
  }

  async lookForTest(clave?:string){

    this.sHotel=[];
    if(!clave) clave= this._hoteldataService.currentConfig.hotel.clave;
    let test_identifiers;

    let index_done = new Promise(
      (resolve,reject)=>{
        this._evalService.evalIndex(clave)
        .subscribe(
          (res:any) => { resolve(res);
            console.log("evalIndex",res)
            if(res.length == 0) this.loading=false;
            else this.loading=true;
          },
          err =>{ console.log("err",err); this.loading=false;},
          ()=>{}
        );
      }
    )

    index_done.then((value:Array<number>)=>{
      if(value.length==0) this.activoNewTest = false;

      test_identifiers = value;

      test_identifiers.forEach(id => {
        this._evalService.evalDetail(clave,id)
        .pipe(
          tap( (val:test) => { val.status = val.status == "activo" }),
        )
        .subscribe(
          (res) =>{

            this.sHotel.push(res);
            this._selectedHotel.playing_score = Number(res.puntuacion_final.toString().split("-")[0]);
            this._selectedHotel.limit_score = Number(res.puntuacion_final.toString().split("-")[1]);
            this._hoteldataService.currentConfig.playing_score = this._selectedHotel.playing_score;
            this._hoteldataService.currentConfig.limit_score = this._selectedHotel.limit_score;
          },
          err => {
            console.log("err",err);
            this.loading=false;
          },
          ()=>{
            this.activoNewTest = this.sHotel.length >= 3;
            if(test_identifiers.length == this.sHotel.length){
              // console.log("this.sHotel",this.sHotel);
              this.ordenar(value);
              this.loading=false;
            }
          }
        )
      });
    });
  }

  ordenar(ids_value:Array<number>){
    let fixingArray:Array<any>=[];
    ids_value.forEach( id => {
      let data = this.sHotel.find( sh=> {return sh.id == id} )
      fixingArray.push(data);
    });
    this.sHotel = fixingArray;
    // console.log("fixingArray",fixingArray);
  }

  makeRandomKey(){
    let random = Math.random();
    return Number(random.toFixed(8)) * 100000000;
  }

  newTest(){
    this._hoteldataService.restore();
    this._hoteldataService.currentConfig.lastVisit = "";
    this.resetLS();
    this._testdataService.resetPreguntas();
    console.log("LS reseted")

    let index= new Promise(
      (resolve,reject)=>{
        this._evalService.evalIndex(this._hoteldataService.currentConfig.data.hotel.clave)
        .subscribe(
          res => resolve(res),
          err => reject(err)
        )
      }
    );

    index.then(res=>{
      const cuestionarios:any = res;
      const hotelKey = this._hoteldataService.currentConfig.data.hotel.clave;
      let secciones, preguntas;
      let newClave:string = "TS"+hotelKey[0]+hotelKey[1]+this.makeRandomKey();

      this.test_new = {
        id:0,//Pending
        clave: newClave,
        saved:true,
        visita: cuestionarios.length+1,
        status: "activo"
      }

      this._hoteldataService.changer("test",this.test_new);
      if (this.sHotel.length) {
        secciones = this.sHotel[this.sHotel.length-1].secciones;
        preguntas = this.sHotel[this.sHotel.length-1].preguntas;
        this._hoteldataService.currentConfig.data.secciones = JSON.parse(secciones);        
        this._hoteldataService.currentConfig.data.preguntas = JSON.parse(preguntas);        
      }

      let _syncData:syncData = {
        cuestionario_clave: newClave,
        hotel_clave: this._hoteldataService.currentConfig.data.hotel.clave,
        visita: cuestionarios.length+1,
        secciones: JSON.stringify(this._hoteldataService.currentConfig.data.secciones) || "[]",
        preguntas: JSON.stringify(this._hoteldataService.currentConfig.data.preguntas) || "[]",
        status: "activo",
        puntuacion_final: "0-0",
        user_id: this._hoteldataService.currentConfig.data.user.user_id,//actualizar user id
      }

      //console.log("_syncData",_syncData);

      this.upload(_syncData,cuestionarios.length+1);

    });

  }

  upload(_sData,visita){
     this._evalService.uploadEval(_sData)
     .pipe(
       tap((x:any)=> {
          this.test_new.id = x.data_saved.id;
          this._hoteldataService.changer("test",this.test_new);
        })
     )
    .subscribe(
      (res:syncData)=>{
        // console.log("visita",visita);
        if(visita == 1) this._router.navigate(['/planning/sort']);
        else this._router.navigate(['/test']);

        console.log("upload Success",Object.keys(res)[0]);
        this._notifications.success(
          "Creando",//title
          "Datos conectados",//content
          this._authService.settings //override
        );
      },
      err=>{
        this._notifications.warn(
          "Precuación",//title
          "No se pudo conectar",//content
          this._authService.settings //override
        );
        console.log("error",err);
      }
    )
  }

  delete(cuestionarioID:test){
    if (!window.confirm(`¿Está completamente seguro de eliminar el cuestionario #${cuestionarioID}?`)) return;
    let confirmPassword = window.prompt("Confirme su contraseña para continuar","Contraseña");
    if (!confirmPassword||confirmPassword=="Contraseña") {
      this._notifications.warn(
        "Precaución",
        `Debes ingresar tu contraseña de ingreso.`,
        this._authService.settings
      );
      return;
    }

    let log = {
      email:this._hoteldataService.currentConfig.data.user.user_mail,
      password:confirmPassword,
    }

    this._authService.login(log)
    .subscribe(ret=>{
        //Delete on server
        this.actuallyDelete(cuestionarioID);
      }, error => {
        console.log(error);
         this._notifications.error(
           "Incorrecto",
           `Los datos no coinciden: ${error.statusText}`,
           this._authService.settings
         );
      });

  }

  queryResults(cuestionarioID: number) {
    this._evalService.getResults(cuestionarioID)
    .subscribe((res: HotelEvaluationScore) => {
        this.showScores = true;
        this.hotelScores = res;
      }, err => {
        console.log('err:', err);
    });
  }

  async actuallyDelete(ID){
    this._evalService.deleteTest(ID)
    .subscribe(
      res=>{
        const resetTest:data_test = {
          id:undefined,
          clave:"",
          saved:false,
          visita:undefined,
          status:"activo"
        }
        this._hoteldataService.changer("test",resetTest);
        this.resetLS();
        
        console.log("res",Object.keys(res)[0]);
        this.lookForTest();
        this._notifications.success("Éxito",`Esta evaluación se ha eliminado`,this._authService.settings);

      },err=>{
        console.log("err",err);
        this._notifications.error("Eror",`Datos correctos, sin embargo no se pudo eliminar`,this._authService.settings);
      }
    )
  }

  resetLS(){
    this._hoteldataService.currentConfig.secciones = [];
    this._hoteldataService.currentConfig.preguntas = [];
    this._hoteldataService.currentConfig.limit_score=0; //if last test assign that value
    this._hoteldataService.currentConfig.playing_score=0; //if last test assign that value
  }

  EditTest(test_clave:string,cuestionarioID:string,whereTo?:boolean,visit?:number,testStatus?:boolean){

    console.log("testStatus",testStatus);
    if(testStatus==false) return;
    console.log("cuestionarioID",cuestionarioID);
    // console.log("whereTo",whereTo);
    // console.log("visit",visit);

    this._testdataService.saveChanges();
    this._hoteldataService.currentConfig.hotel = this._selectedHotel;
    this._hoteldataService.currentConfig.hotel.clave = this._selectedHotel.clave;
    if(this._hoteldataService.currentConfig.test.id !== cuestionarioID) this._hoteldataService.currentConfig.lastVisit = "";
    this._hoteldataService.currentConfig.test.id = cuestionarioID;

    this._hoteldataService.currentConfig.test.visita = visit || 1;

    let loadedSections;
    let clave = this._hoteldataService.currentConfig.hotel.clave;

    this._evalService.evalDetail(clave,cuestionarioID)
    .subscribe(
      (res:any)=>{
        loadedSections = res;
        //console.log("retrieve test",res);

          console.log("lastVisit:",this._hoteldataService.currentConfig.lastVisit);
          console.log("¿lastVisit == ''?",this._hoteldataService.currentConfig.lastVisit=="");
          
          console.log("test_clave matches!;",test_clave !== loadedSections.cuestionario_clave);

          

        if(test_clave !== loadedSections.cuestionario_clave || this._hoteldataService.currentConfig.lastVisit==""){
          console.log("Fetching test from server");

          this._hoteldataService.currentConfig.secciones = [];
          this._hoteldataService.currentConfig.preguntas = [];
          this._hoteldataService.currentConfig.lastVisit = "";
  
          this._hoteldataService.currentConfig.secciones = JSON.parse(loadedSections.secciones);
          this._hoteldataService.currentConfig.preguntas = JSON.parse(loadedSections.preguntas);
          this._hoteldataService.currentConfig.test.clave = loadedSections.cuestionario_clave;
  
          this._hoteldataService.forceUpload.next(this._hoteldataService.currentConfig.data);

          this._testdataService.resetPreguntas();
        }


        if(whereTo)this._router.navigate(['/test']);
        else this._router.navigate(['/planning/sort']);
      }
    )

    //loadedSections = this._testdataService.savedTest.find(ind => { return ind.id == cuestionarioID}); //|| {secciones:[]}

  }

    loadServerData(){
      
    }

    block(test){
    this._hoteldataService.restore();

    let index = this.sHotel.indexOf(test);
    console.log("test index =",index)
    this.sHotel[index].status = !this.sHotel[index].status;
    let status:boolean|string = this.sHotel[index].status?"activo":"false";

    // console.log("id: ",test.id);
    // console.log("index: ",index);
    // console.log("Blocked?: ",status);

    // let sync = this._evalService.syncData;
    // sync.cuestionario_clave = this.sHotel[index].cuestionario_clave;
    // sync.status = status.toString();
    // sync.visita = this.sHotel[index].visita;
    // sync.preguntas = this.sHotel[index].preguntas;
    // sync.secciones = this.sHotel[index].secciones;
    // sync.puntuacion_final = this.sHotel[index].puntuacion_final;

    let sync = {
      "status" : status.toString()
    }

    this._hoteldataService.currentConfig.test.status = sync.status;
    this._hoteldataService.forceUpload.next(this._hoteldataService.currentConfig.data);


    this._evalService.updateEval(sync,test.id)
    .subscribe(
      res=>{
        console.log("res",Object.keys(res)[0]);
        // console.log("data",res)
      },
      err=>{
        console.log("err",err);
      }
    )

  }

  @ViewChild('JsonAnchor') nativeJsonAnchor:ElementRef;
  JsonHref:any;
  JsonStored:any;

  retrieveHotels(){
    if(this.loadHtls == 1){
      this._hotelService.hotelList()
      .subscribe(
        res=>{
          console.log("res",res);
          this.hoteles = res;
        });
        err=>console.log;
        ()=>{
          console.log("Hotels retrieved");
        }
      this.loadHtls = 2
    }else return;

  }

  ngOnInit() {
    this.loadHtls = 1;

    //this._testdataService.saveChanges();
    this._hoteldataService.restore();
    //this.blockTestShortcut= this._hoteldataService.currentConfig.secciones.length == 0;
    //this.lookForTest();

    if(!this._hoteldataService.currentConfig.data.hotel.clave) console.log("No hay datos almacenados");
    else {
      this.clicked(this._hoteldataService.currentConfig.data.hotel.clave);
      
    }
  }

}
