import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FootheadService {

  constructor() { }

  selectTitle(dir){
    switch (dir) {
      case "/acceso":
        return "Obtener usuario";
      break;
      case "/atomic":
        return "ATOMIC!"
      break;
      case "/planning":
        return "Evaluación"
      break;
      default:
        return "Evaluación Fibra";
      break;
    }
  }

}
