import { Injectable, OnInit } from '@angular/core';
import { Hotels, Sections } from '../planning/planning';
import { CurrentConfig, data } from '../services/current-config'
import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

export interface plantilla {
  name:string,
  secciones:Array<string>
}

@Injectable({
  providedIn: 'root'
})
export class HoteldataService{

  forceUpload : Subject<any> = new Subject<any>();
  configChanged : Subject<any> = new Subject<any>();
  currentConfig:any|data;


  constructor() {
    this.currentConfig = new CurrentConfig();
    this.currentConfig.changes
    .subscribe(
      res=>{
        this.saveLocalData(res);
      },err=>{console.log("err: ",err)},()=>{console.log("finished subs")}
    )

    this.forceUpload
    .subscribe(
      res=>{ this.saveLocalData(res) }
    )

  }

  LSpreset:data={
    user:{},
    limit_score:0,
    playing_score:0,
    secciones:[],
    hotel:{},
    test:{},
    preguntas:[],
    lastVisit:''
  }

  saveLocalData(val){
    let dataMod = JSON.stringify(val);
    localStorage.setItem("fibraSettings",dataMod);
  }

  //update config with localStorage data
  restore(){
    let lconfig = localStorage.getItem("fibraSettings");

    if (lconfig!=="") {
      this.currentConfig.data=JSON.parse(lconfig);
      return true;
    }else{
      return false;
    }
  }

  changer(property:string,insert:any){
    this.currentConfig[property] = insert;
    this.configChanged.next(true);
    console.log(`this.currentConfig.data[${property}]`,this.currentConfig.data[property]);
  }

  makeLimitScore(sectionsKeys:Array<string>){
    let sum:number=0;
    sectionsKeys.forEach( sK=>{
      let points = this.Secciones.find( S => sK == S.id );
      sum = sum + points.total_score;
    })
    return sum;
  }

  Hoteles:Array<Hotels> = [];

  Secciones:Array<Sections> = [
    { id:"1ADMN", name:"Administración",type: "fijo", total_score:26,selected:true},
    { id:"2FCHE", name:"Fachadas y exterior de oficina",type: "fijo", total_score:9,selected:true},
    { id:"3HBTS", name:"Habitaciones",type: "fijo", total_score:16.5,selected:true},
    { id:"4BNHB", name:"Baños de habitaciones",type: "fijo", total_score:11,selected:true},
    { id:"5PSHB", name:"Pasillos de habitaciones",type: "fijo", total_score:14,selected:true},
    { id:"6LBRC", name:"Lobby recepción",type: "fijo", total_score:8,selected:true},
    { id:"7BNPB", name:"Baños públicos",type: "fijo", total_score:5.5,selected:true},
    { id:"8CENG", name:"Centro de negocios",type: "opcional", total_score:3.5,selected:false},
    { id:"9SLJS", name:"Sala de juntas",type: "opcional", total_score:3.5,selected:false},
    { id:"10GMS", name:"Gimnasio",type: "opcional", total_score:8,selected:false},
    { id:"11ALB", name:"Alberca",type: "opcional", total_score:6,selected:false},
    { id:"12LBR", name:"Lobby Bar / Isla",type: "opcional", total_score:8,selected:false},
    { id:"13RST", name:"Restaurante",type: "opcional", total_score:10,selected:false},
    { id:"14SLN", name:"Salones",type: "opcional", total_score:6,selected:false},
    { id:"15FYR", name:"Foyer",type: "opcional", total_score:5.5,selected:false},
    { id:"16BOH", name:"Back of the house",type: "fijo", total_score:4,selected:true},
    { id:"17CNA", name:"Cocina",type: "fijo", total_score:23,selected:true},
    { id:"18CFR", name:"Cámaras frías",type: "opcional", total_score:6.5,selected:false},
    { id:"19ARF", name:"Aire acondicionado / refrigeración",type: "opcional", total_score:12.5,selected:false},
    { id:"20IEL", name:"Instalación eléctrica",type: "opcional", total_score:7 ,selected:false},
    { id:"21LVD", name:"Lavandería",type: "opcional", total_score:4.5,selected:false},
    { id:"22SEL", name:"Subestación eléctrica",type: "fijo", total_score:5.5,selected:true},
    { id:"23CMQ", name:"Casas de máquinas",type: "fijo", total_score:17,selected:true},
    { id:"24CRG", name:"Control de riesgos",type: "fijo", total_score:42.5,selected:true},
  ];
    //all sections value = 263

  PlantillasSecciones:Array<plantilla>=[
    {name:"Servicios completos", secciones: ["1ADMN","2FCHE","3HBTS","4BNHB","5PSHB","6LBRC","7BNPB","8CENG","9SLJS","10GMS","11ALB","12LBR","13RST","14SLN","15FYR","16BOH","17CNA","22SEL","23CMQ","24CRG"]},
    {name:"Servicios selectos", secciones: ["1ADMN","2FCHE","3HBTS","4BNHB","5PSHB","6LBRC","7BNPB","8CENG","9SLJS","10GMS","11ALB","12LBR","13RST","14SLN","15FYR","16BOH","17CNA","22SEL","23CMQ","24CRG"]},
    {name:"Hoteles resort", secciones: ["1ADMN","2FCHE","3HBTS","4BNHB","5PSHB","6LBRC","7BNPB","8CENG","9SLJS","10GMS","11ALB","12LBR","13RST","14SLN","15FYR","16BOH","17CNA","22SEL","23CMQ","24CRG"]},
    {name:"Servicios limitados", secciones: ["1ADMN","2FCHE","3HBTS","4BNHB","5PSHB","6LBRC","7BNPB","8CENG","9SLJS","13RST","16BOH","17CNA","19ARF","20IEL","22SEL","23CMQ","24CRG"]},
  ]

}
