import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { HoteldataService, plantilla } from '../../services/hoteldata.service'
import { Router } from '@angular/router';
import { Hotels, Sections } from '../planning';
import { TestdataService } from 'src/app/services/testdata.service';
import { EvalService } from 'src/app/rest/eval.service';
import { mergeMap, last, pluck } from 'rxjs/operators';

@Component({
  selector: 'app-sort',
  templateUrl: './sort.component.html'
})
export class SortComponent implements OnInit {
  toogleSection:boolean = false;
  currentEditSection:string = 'opcionales';
  title:string = "Secciones cuestionario";
  selectedAll:boolean = false;

  constructor(
    private _hoteldataService : HoteldataService,
    private _testdataService : TestdataService,
    private _router : Router,
    private _evalService : EvalService
    ) {

      this._hoteldataService.restore();
      const clave = this._hoteldataService.currentConfig.data.hotel.clave;

      this._evalService.evalIndex(clave).pipe(
        last(),
        mergeMap((id:string)=>this._evalService.evalDetail(clave,id)),
        pluck("id")
        ).subscribe(
          (res:number)=>{

            const test_new = {
              id:res || null,
              clave: this._hoteldataService.currentConfig.data.test.clave,
              saved:true,
              visita: this._hoteldataService.currentConfig.data.test.visita,
              status: this._hoteldataService.currentConfig.data.test.status
            }

            this._hoteldataService.changer("test",test_new);
        }
      );

    }

  @ViewChild('selPlantilla') nativeSelPlantilla: ElementRef;

  // For select info
  currentPlantilla:string;

  get plantillaValue(){
    return this.nativeSelPlantilla.nativeElement.value;
  }

  set plantillaValue(value){
    this.nativeSelPlantilla.nativeElement.value=value;
  }

  //array secciones
  infoSecciones:Array<Sections> = this._hoteldataService.Secciones;
  //array Hoteles
  hoteles:Array<Hotels> = this._hoteldataService.Hoteles;
  //array claves secciones
  CurrentSections: Array<string> = this._hoteldataService.currentConfig.data.secciones;
  //arrray plantillas
  plantillasSections:Array<plantilla> = this._hoteldataService.PlantillasSecciones;

  setPlantilla(){
    this.currentPlantilla = this.plantillaValue;
    this.emptyAll();
    let obtainedPlantilla:plantilla = this.plantillasSections.find(val =>{
      return val.name == this.plantillaValue;
    })
    obtainedPlantilla.secciones.forEach( key =>{
      let seccion = this.infoSecciones.find(returned=>{ return returned.id == key});
      let index = this.infoSecciones.indexOf(seccion);
      this.infoSecciones[index].selected = true;
    });
    this.CurrentSections = obtainedPlantilla.secciones;
    this._hoteldataService.currentConfig.data.secciones = this.CurrentSections;
    this._hoteldataService.currentConfig.data.test.saved=false;
  }

  switchEdition(){
    this.toogleSection = !this.toogleSection;
    this.currentEditSection = this.toogleSection?'fijas':'opcionales';
  }

  back(){
    this.autosave();
    this._router.navigate(['/planning']);
  }

  send(){
    if(!this._hoteldataService.currentConfig.data.test.id)return;
    this._hoteldataService.restore();

    // let _syncData = this._evalService.syncData;

    let _limitScore:number = this._hoteldataService.makeLimitScore(this.CurrentSections);
    let _finalScore:string = `${this._hoteldataService.currentConfig.data.playing_score} - ${_limitScore}`;

    // _syncData.cuestionario_clave = this._hoteldataService.currentConfig.data.test.clave;
    // _syncData.id = this._hoteldataService.currentConfig.data.test.id;
    // _syncData.puntuacion_final = _finalScore;
    // _syncData.secciones = JSON.stringify(this.CurrentSections);

    this._hoteldataService.currentConfig.limit_score = _limitScore;

    let _syncData = {
      "puntuacion_final" : _finalScore,
      "secciones" : JSON.stringify(this.CurrentSections)
    }

    if(!this._hoteldataService.currentConfig.data.test.id || this._hoteldataService.currentConfig.data.test.id == ""){
      console.log("There was an error,test id is missing",this._hoteldataService.currentConfig.data.test.id);
      return;
    }

    this._evalService.updateEval(_syncData,this._hoteldataService.currentConfig.data.test.id)
    .subscribe(
      res=>{
        console.log("res",Object.keys(res)[0]);
        this._hoteldataService.currentConfig.data.test.saved=true;
        this._router.navigate(['/planning/arrange']);
      },err=>{
        console.log("err",err);
      }, () => {
        //console.log("saved sections",_syncData.secciones);
      }
      )

    this.autosave();
  }


  toggleSection(choosen){
    let index = this.CurrentSections.indexOf(choosen.id);
    if(index == -1){
      this.CurrentSections.push(choosen.id);
    } else{
      this.CurrentSections.splice(index,1);
    }
    choosen.selected = !choosen.selected;
    this._hoteldataService.currentConfig.data.test.saved=false;
    this.autosave();
  }

  emptyAll(){
    let tipo:string = this.toogleSection ? "fijo" : "opcional";

    this.infoSecciones.forEach(sec=>{
      if (sec.type==tipo) {
        sec.selected = false;
        let borrar_index = this.CurrentSections.indexOf(sec.id);
        if(borrar_index!==-1) this.CurrentSections.splice(borrar_index,1);
      }
    });

    this._hoteldataService.currentConfig.data.secciones = this.CurrentSections;
    this._hoteldataService.currentConfig.data.test.saved=false;
  }

  fillAll(){
    let tipo:string = this.toogleSection ? "fijo" : "opcional";
    this.infoSecciones.forEach(sec=>{
      if (sec.type==tipo){
        sec.selected = true;
        let doesItexistQ = this.CurrentSections.indexOf(sec.id);
        if(doesItexistQ==-1) this.CurrentSections.push(sec.id);
      }
    });
    this._hoteldataService.currentConfig.data.secciones = this.CurrentSections;
    this._hoteldataService.currentConfig.data.test.saved=false;
  }

  autosave(){
    let added:Array<string> = [];
    let configSecs:Array<string> = this._hoteldataService.currentConfig.data.secciones;

    configSecs.forEach( _confSec => {
      let founded:string = this.CurrentSections.find( _current => {
        return _current == _confSec;
      });
      if (founded == undefined) {
        let index = configSecs.indexOf(_confSec);
        configSecs.splice(index,1);
      }
    });

    this.CurrentSections.forEach( _current => {
      let founded = configSecs.find( _confSec => {
        return _current == _confSec;
      });
      if (founded == undefined) added.push(_current);
    });

    let final = configSecs.concat(added);
    this._hoteldataService.currentConfig.secciones=final;
    // Revisar esta parte
    // this._hoteldataService.currentConfig.data.secciones=this.CurrentSections;
    //this._testdataService.strdData();
  }

  ngOnInit() {
    this.CurrentSections = [];
    this.infoSecciones.forEach(seccion=>{
      let match = this._hoteldataService.currentConfig.data.secciones.find( key =>{
        return key == seccion.id;
      });
      seccion.selected = !match?false:true;
      if(seccion.selected)this.CurrentSections.push(seccion.id);
    });
    this.selectedAll = this.CurrentSections.length == 24;
  }

}
