import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FootheadService } from 'src/app/services/foothead.service';
import { HoteldataService } from 'src/app/services/hoteldata.service';


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html'
})
export class FooterComponent implements OnInit {

  direction:string;
  username:string;
  evalCount:number;

  constructor(
    _router:Router,
    _foothead : FootheadService,
    private _hotelData : HoteldataService
  ) {

    _router.events.subscribe(
      val => {
        this.direction = _router.url;
        //console.log("Footer val:",val);
      }
    );
    //console.log("this._conf",this._conf);
  }

  ngOnInit() {
    this._hotelData.configChanged
    .subscribe(
      res=>{
        //console.log("There was changes",res)
        this.username = this._hotelData.currentConfig.data.user.user_name;
        this.evalCount = this._hotelData.currentConfig.data.user.user_eval_count;
      }
    )
  }

}
