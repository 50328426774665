import { Subject } from 'rxjs';

export interface data{
  user:data_user,
  playing_score?:number,
  limit_score?:number,
  secciones?:Array<string>,
  hotel?:data_hotel,
  test?:data_test,
  preguntas?:Array<any>,
  lastVisit?:string
}

export interface data_hotel{
  id?:number,
  name?:string,
  clave?:string,
  edad?:number,
  gerente?:string,
  habitaciones?:number,
  logo?:string,
  segmento?:string
}

export interface data_user{
  user_id?:number,
  user_name?:string,
  user_mail?:string,
  user_eval_count?:number,
  user_avatar?:string,
  user_cargo?:string,
}

export interface data_test{
  id?:number|string,
  clave?:any,
  saved?:boolean,
  visita?:number,
  status?:string,
}

export class CurrentConfig {

  data:data={
    user:{
      user_id:0,
      user_name:"",
      user_mail:"",
      user_eval_count:0,
      user_avatar:"",
      user_cargo:"",
    },
    limit_score:0,
    playing_score:0,
    secciones:[],
    hotel:{
      id:0,
      name:"",
      clave:"",
      edad:0,
      gerente:"",
      habitaciones:0,
      logo:"",
      segmento:""
    },
    test:{
      id:0,
      clave:"",
      saved:false,
      visita:0,
      status:"activo"
    },
    preguntas:[],
    lastVisit:""
  }

  changes:Subject<any> = new Subject<any>();

  constructor(){
    for(let key in this.data){
      Object.defineProperty(this,key,{
        get: () => {
          return this.data[key]; },
        set: (value:any) => {
          this.data[key] = value;
          this.changes.next(this.data);
        }
      })
    }
  }

}
