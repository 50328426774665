import { Component, OnInit, ElementRef, ViewChild, Output, EventEmitter, Input } from '@angular/core';
import { HoteldataService } from 'src/app/services/hoteldata.service';
import { Sections } from 'src/app/planning/planning';
import { TestdataService, questions } from 'src/app/services/testdata.service';
import { EvalService } from 'src/app/rest/eval.service';
import { AuthService } from 'src/app/rest/auth/auth.service';
import { NotificationsService } from 'angular2-notifications';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { mergeMap, tap, pluck } from 'rxjs/operators';

@Component({
  selector: 'app-summary',
  templateUrl: './summary.component.html'
})
export class SummaryComponent implements OnInit{
  summ_preguntas:Array<any>;
  summ_secciones:Array<string>;

  @Input("playing_score") playingScore: number;
  @Input("limit_score") limitScore:number;

  @ViewChild("selectorsec") selectorsec:ElementRef;

  @Output() openSummary = new EventEmitter<boolean>();
  @Output() sendQuestion = new EventEmitter<any>();
  @Output() eval = new EventEmitter<any>();
  @Output() save = new EventEmitter<boolean>();
  @Output() updateSectionTest = new EventEmitter<any>();

  private _actualSec:string;

  get actualSec(){
    this._actualSec = this.selectorsec.nativeElement.value;
    return this._actualSec;
  }

  set actualSec(value:string){
    this._actualSec = value;
    this.selectorsec.nativeElement.value = this._actualSec;
  }

  constructor(
    private _router : Router,
    private _hotelData : HoteldataService,
    private _testData : TestdataService,
    private _evalService : EvalService,
    private __authService : AuthService,
    public _notifications: NotificationsService,
  ) {
  }

  nameSection(clave:string){
    let section = this._hotelData.Secciones.find( (sec:Sections) => { return sec.id == clave } );
    return section.name;
  }

  // addPreguntas(){
  //   this._testData.Preguntas.forEach(
  //     pregunta=>{

  //     }
  //   )
  // }

  checkForPregs(sccion?:string){
    if(!sccion) sccion = this.selectorsec.nativeElement.value;

    this.summ_preguntas = [];
    this._hotelData.currentConfig.data.preguntas.forEach(
      preg => {
        let section:string = preg[0].slice(-5);
        let fullQuestion = this._testData.Preguntas.find( (x:questions)=> { return x.id == preg[0]} )

        fullQuestion.id = preg[0];
        fullQuestion.cuestionario_id = preg[1];
        fullQuestion.respuesta = preg[2];
        fullQuestion.marca = preg[3];
        fullQuestion.fecha = preg[4];
        fullQuestion.hora = preg[5];
        fullQuestion.capex = preg[6];
        fullQuestion.notas = preg[7];
        fullQuestion.fotos = preg[8];

        let alreadyExist = this.summ_preguntas.indexOf(fullQuestion) !== -1 ;

        if(section == sccion && !alreadyExist) this.summ_preguntas.push(fullQuestion);
      });
      //console.log("this.summ_preguntas",this.summ_preguntas);
    }

  getOut(){
    this.openSummary.emit(false);
    this.updateSectionTest.emit(this.selectorsec.nativeElement.value);
  }

  yesToAll(yta_section?:string){
    //this._hotelData.restore();

    if(!confirm(`¿Desea añadir todas las preguntas sin contestar ${yta_section?`en la sección ${yta_section}`:`en todo el cuestionario ${this._hotelData.currentConfig.hotel.name} ${this._hotelData.currentConfig.test.visita}ª visita`} ?`)) return

    let newArray : Array<any> = [];
    newArray = this._hotelData.currentConfig.data.preguntas;

    if(yta_section!==undefined){
      this._testData.Preguntas.forEach( (p:questions)=>{
        if(p.seccion_clave == yta_section){
          let doesExist = this._hotelData.currentConfig.data.preguntas.find( x=>{ return x[0] == p.id } )
          if(doesExist==undefined){
            newArray.push(this.newAutomateAnswer(true,p.id));
            //console.log("ONLY SECTION this is happening dude, at:",p.id);
          }else{
            if(doesExist[2]=="undefined"){
              let posIndex = this._hotelData.currentConfig.data.preguntas.indexOf(doesExist);
              this._hotelData.currentConfig.preguntas[posIndex][2]=true;
            }
          }
        }
      });
    }else{
      console.log(`yesToAll with ${yta_section}, happened`);
      this._testData.Preguntas.forEach( (p:questions)=>{
        let sectionDefined = this._hotelData.currentConfig.secciones.find(s => s== p.seccion_clave );
        if(sectionDefined!==undefined){
          let doesExist = this._hotelData.currentConfig.data.preguntas.find( x=>{ return x[0] == p.id } )
          if(doesExist==undefined){
            newArray.push(this.newAutomateAnswer(true,p.id));
            //console.log("WHOLE TEST this is happening dude, at:",p.id);
          }else{
            if(doesExist[2]=="undefined"){
              let posIndex = this._hotelData.currentConfig.data.preguntas.indexOf(doesExist);
              this._hotelData.currentConfig.preguntas[posIndex][2]=true;
            }
          }
        }
      });
    }

    this._hotelData.currentConfig.preguntas = newArray;
    this.save.emit(false);
    this._hotelData.forceUpload.next(this._hotelData.currentConfig.data)
  }

  newAutomateAnswer(sel:boolean,pregId:string){
    let newQuestion:Array<any> = []

    let ponderacion = this._testData.Preguntas.find(x=>{return x.id==pregId})

    newQuestion =[
      pregId,
      this._hotelData.currentConfig.data.test.id,
      sel,
      0,
      moment().format('DD[/]MM[/]YYYY'),
      moment().format('h:mm:ss a'),
      false,
      [],
      [],
      ponderacion.valor

    ];

    return newQuestion;
  }

  sendReporte(){

    if(!confirm(`Está seguro de envíar el ${this._hotelData.currentConfig.data.test.visita}° reporte del hotel ${this._hotelData.currentConfig.data.hotel.name}`)) return;

    const reporte = {
      cuestionario_clave: this._hotelData.currentConfig.data.test.clave,
      hotel_clave: this._hotelData.currentConfig.data.hotel.clave,
      visita: this._hotelData.currentConfig.data.test.visita||0,
      secciones: JSON.stringify(this._hotelData.currentConfig.data.secciones) || "''",
      preguntas: JSON.stringify(this._hotelData.currentConfig.data.preguntas) || "''",
      status: "false",
      puntuacion_final: `${this._hotelData.currentConfig.data.playing_score} - ${this._hotelData.currentConfig.data.limit_score}`,
      user_id: this._hotelData.currentConfig.data.user.user_id
    }

    const saveEval = {
      secciones: reporte.secciones,
      preguntas: reporte.preguntas,
      status: "false",
      puntuacion_final: reporte.puntuacion_final,
      user_id: reporte.user_id
    }

    this._evalService.reporte(reporte)
    .pipe(
      pluck("data_saved"),
      pluck("id"),
      tap(x=>console.log("tap x reporte:",x)),
      // tap(x=>{console.log("ID",this._hotelData.currentConfig.data.test.id)}),
      mergeMap( x =>  this._evalService.updateEval(saveEval,this._hotelData.currentConfig.data.test.id))
    )
    .subscribe(
      res=>{ console.log("Reportado",res)
        this._notifications.success(
            "Reporte",//title
            "Enviado satisfactoriamente",//content
            this.__authService.settings //override
          );
      },
      err=> {console.log("Hubo un error",err)
        this._notifications.warn(
            "Advertencia",//title
            "Imposible envíar",//content
            this.__authService.settings //override
          );
      },
      ()=>{
        setTimeout(() => {
          this._router.navigate(['/planning']);
        }, 1500);
      }
    )

  }

  sendPregunta(pregunta:questions){
    const datos = {
      sendpregunta : pregunta,
      sendseccion : this.actualSec
    }

    this.openSummary.emit(false);
    this.sendQuestion.emit(datos);
  }

  changeCalification(newAnswer:boolean,idQuestion:number){
    const data = {
      Value : newAnswer,
      ID : idQuestion
    }
    this.eval.emit(data)
  }

  ngOnInit(){
    this._hotelData.restore();

    this.summ_secciones = this._hotelData.currentConfig.data.secciones;

    setTimeout(() => {
      this.actualSec = this._hotelData.currentConfig.data.lastVisit.slice(-5);
    }, 300);

    // this.checkForPregs(this.summ_secciones[0]);
    this.checkForPregs(this._hotelData.currentConfig.data.lastVisit.slice(-5));

    this._hotelData.forceUpload.subscribe(
      res=>{
        this.checkForPregs(this.actualSec)
        this.playingScore = this._hotelData.currentConfig.data.playing_score;
      }
    )
  }

}
