import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-newuser',
  templateUrl: './newuser.component.html'
})
export class NewuserComponent implements OnInit {
  lines:boolean = false;
  newuser:FormGroup;

  get name(){
    return this.newuser.get('name').value;
  }

  get mail(){
    return this.newuser.get('mail').value;
  }

  get charge(){
    return this.newuser.get('charge').value;
  }

  constructor(
    private _formBuilder:FormBuilder,
    private _router:Router,
  ) {
    this.newuser = this._formBuilder.group({
      name: ['', Validators.required],
      mail: ['', Validators.required],
      charge: ['', Validators.required]
    })
  }

  async submitNewUser() {
    this.lines = false;
    setTimeout(() => {
      this._router.navigate(['']);
    }, 620);
  }

  ngOnInit() {
    setTimeout(() => {
      this.lines = true;
    }, 500);
  }

}
